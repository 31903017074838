import { classNames } from "@ds/classNames"
import { Participant } from "./Participant"
import { MessageText, useChannelStateContext, useChatContext, useMessageContext } from "stream-chat-react"
import { useEffect, useRef } from "react"
import { format } from "date-fns"
import { Check } from "@ds/icons/Check.tsx"
import { PrevisualisationDocument } from "@features/messagerie/PrevisualisationDocument.tsx"

export const CustomMessage = () => {
  const { message, groupStyles, lastReceivedId } = useMessageContext()
  const { client } = useChatContext()
  const user = message.user
  const role = message.user?.role
  const lastGroupMessage = groupStyles?.includes("single") || groupStyles?.includes("bottom")
  const haveMarginOnTop = groupStyles?.includes("single") || groupStyles?.includes("top")
  const soignant = role === "soignant"
  const fromMe = message.user?.id === client.user?.id
  const messageDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (message.id === lastReceivedId && messageDiv.current?.parentElement?.parentElement) {
      const messagesList = messageDiv.current.parentElement.parentElement
      messagesList.scrollBy({ top: messagesList.scrollHeight, behavior: "smooth" })
    }
  }, [message, lastReceivedId])

  return (
    <div
      ref={messageDiv}
      className={classNames(
        "mx-2 flex items-end gap-1",
        fromMe ? "flex-row-reverse justify-start" : "justify-start",
        haveMarginOnTop ? "mt-4" : "mt-0.5",
      )}
    >
      <div className="h-6 w-6">
        {user && lastGroupMessage && (
          <Participant
            className="h-6 w-6"
            id={user.id}
            prenom={typeof user?.prenom === "string" ? user?.prenom : undefined}
            nom={typeof user?.nom === "string" ? user?.nom : undefined}
            urlAvatar={typeof user?.urlAvatar === "string" ? user?.urlAvatar : undefined}
          />
        )}
      </div>
      <MessageText
        renderText={(text) => (
          <>
            <div>
              {message.extensionDuDocument !== undefined && message.uuidDuDocument !== undefined && (
                <div className="pb-2">
                  <PrevisualisationDocument
                    identifiantDuDocument={message.uuidDuDocument as string}
                    type={message.extensionDuDocument as string}
                    nom={message.nomDuDocument as string}
                  />
                </div>
              )}
              <span className="whitespace-pre-wrap">
                {message.uuidDuDocument !== undefined ? text?.replace(/^Document envoyé !\s*/, "") : text}
              </span>
              <span className={"inline-block"}>
                <div className={classNames(soignant ? "w-14" : "w-10")} />
              </span>
            </div>
            <div className="float-right -mt-3 flex items-center gap-1 text-p-tiny text-grey">
              <div>{format(message.created_at!, "HH:mm")}</div>
              <CocheLu />
            </div>
          </>
        )}
        customWrapperClass={classNames(
          "max-w-md rounded-xl p-3 pb-2 text-p-small",
          soignant ? "bg-secondary-green" : "bg-extra-light-grey",
          fromMe ? "rounded-bl-xl" : "rounded-br-xl",
          {
            "rounded-tl-none": (groupStyles?.includes("middle") || groupStyles?.includes("bottom")) && !fromMe,
            "rounded-tr-none": (groupStyles?.includes("middle") || groupStyles?.includes("bottom")) && fromMe,
            "rounded-bl-none": (groupStyles?.includes("middle") || groupStyles?.includes("top")) && !fromMe,
            "rounded-br-none": (groupStyles?.includes("middle") || groupStyles?.includes("top")) && fromMe,
          },
        )}
      />
    </div>
  )
}

const CocheLu = () => {
  const { message } = useMessageContext()
  const { channel } = useChannelStateContext()
  if (message.user?.role !== "soignant") return

  const userPatient = Object.values(channel.state.members).find((member) => member.user?.role === "patient")
  if (!userPatient?.user_id) return

  const readState = channel.state.read[userPatient.user_id]
  const lastReadMessageDate = readState?.last_read

  if (!message.created_at || typeof message.created_at === "string") {
    return
  }

  if (message.created_at.getTime() > lastReadMessageDate?.getTime()) return

  return <Check className="stroke-blue" />
}
