import { useState } from "react"

type UseChampsPresentesProps = {
  nouveauPatient?: boolean
}

export const useChampsPresentes = ({ nouveauPatient }: UseChampsPresentesProps) => {
  const [champsPresentes, setChampsPresentes] = useState(
    nouveauPatient ? ["PAS", "PAD", "Temp", "FC", "Poids", "Taille"] : ["PAS", "PAD", "Temp", "FC"],
  )

  const ajouterUnChampPresente = (code: string) => {
    setChampsPresentes([...champsPresentes, code])
  }

  const supprimerUnChampPresente = (code: string) => {
    setChampsPresentes(champsPresentes.filter((champ) => champ !== code))
  }

  return {
    champsPresentes,
    ajouterUnChampPresente,
    supprimerUnChampPresente,
  }
}
