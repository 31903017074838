import { graphql } from "@data/gql"

export const enregistrerDonneesBiometriques = graphql(`
  mutation enregistrerDonneesBiometriques($identifiantDuRendezVous: String!, $donnees: DonneesBiometriquesInput!) {
    enregistrerDonneesBiometriques(identifiantRendezVous: $identifiantDuRendezVous, donneesBiometriques: $donnees) {
      frequenceCardiaque {
        valeurEnBPM
      }
    }
  }
`)
