import { SVGProps } from "react"

export const House = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.98242 8.25367C1.98242 7.90822 2.1447 7.58284 2.42064 7.37501L9.10208 2.34272C9.48401 2.05507 10.008 2.04707 10.3985 2.32295L17.552 7.37635C17.8438 7.58249 18.0173 7.91751 18.0173 8.27478V17.04C18.0173 17.6475 17.5248 18.14 16.9173 18.14H3.08242C2.47491 18.14 1.98242 17.6475 1.98242 17.04V8.25367Z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M6.29085 12.6834C6.32031 12.9053 6.41407 13.0489 6.56121 13.2127C6.61233 13.2695 6.69307 13.312 6.75438 13.3578C6.96229 13.513 7.15769 13.6875 7.36588 13.8411C7.75629 14.129 8.26052 14.2351 8.72469 14.3498C8.90929 14.3953 9.08675 14.4424 9.27523 14.4762C9.44135 14.5061 9.60523 14.529 9.77429 14.5366C9.89619 14.5421 10.0704 14.596 10.1895 14.5754C10.5709 14.5095 10.9933 14.5922 11.3636 14.4582C11.6123 14.3682 11.8633 14.3008 12.1058 14.1953C12.297 14.1121 12.4494 13.9929 12.6254 13.8793C12.8844 13.7121 13.1198 13.5647 13.3381 13.3464C13.5708 13.1137 13.8672 12.8147 13.928 12.4763"
      strokeLinecap="round"
    />
  </svg>
)
