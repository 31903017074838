import { SVGProps } from "react"

export const ChevronUp = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <div data-testid="chevron-up">
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.178 10.857 9.5 6.536l4.321 4.321"
      />
    </svg>
  </div>
)
