import { useLocalStorage } from "usehooks-ts"

export interface Preferences {
  identifiantDuCabinet: string | null
  sideBarOuverte: boolean
  filtreMedecin?: { [key: string]: { id: string; name: string }[] }
  filtreMessagerie: string | null
}

const initialPreferences = { identifiantDuCabinet: null, sideBarOuverte: true, filtreMessagerie: "ma-boite" }

export const usePreferences = () => {
  const [preferences, setLocalStoragePreferences] = useLocalStorage<Preferences>("preferences", initialPreferences)

  if (!preferences.filtreMessagerie) {
    preferences.filtreMessagerie = "ma-boite"
  }

  const setPreferences = (nouvellesPreferences: Partial<Preferences>) => {
    setLocalStoragePreferences({ ...preferences, ...nouvellesPreferences })
  }

  return {
    setPreferences,
    ...preferences,
  }
}
