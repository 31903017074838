import { graphql } from "@data/gql"

export const validerPreconsultationMutation = graphql(`
  mutation validerPreconsultationMutation($identifiantDuRendezVous: String!) {
    validerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous) {
      id
      validee
    }
  }
`)
