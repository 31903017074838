import { SVGProps } from "react"

export const Height = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      stroke="#101010"
      strokeWidth={0.3}
      d="M10.023 10.596c-4.472 0-7.976-1.804-7.976-4.11 0-.164.122-.297.273-.297.15 0 .272.133.272.297 0 1.906 3.402 3.516 7.431 3.516 4.03 0 7.431-1.61 7.431-3.516 0-.164.122-.297.273-.297.151 0 .273.133.273.297 0 2.304-3.504 4.11-7.977 4.11ZM10.025 15.998c-4.474 0-7.978-1.804-7.978-4.107 0-.165.122-.298.273-.298.15 0 .272.133.272.297 0 1.906 3.404 3.514 7.433 3.514 4.03 0 7.431-1.61 7.431-3.514 0-.164.122-.297.273-.297.15 0 .273.133.273.297 0 2.304-3.504 4.108-7.977 4.108Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M17.732 12.187c-.151 0-.273-.133-.273-.298V6.486c0-.164.122-.297.273-.297.15 0 .272.133.272.297v5.403c0 .165-.121.298-.272.298ZM2.32 12.187c-.151 0-.273-.133-.273-.298V6.486c0-.164.122-.297.273-.297.15 0 .272.133.272.297v5.403c0 .165-.121.298-.272.298ZM4.628 14.912c-.15 0-.273-.133-.273-.298v-2.403c0-.164.122-.297.273-.297.151 0 .273.133.273.297v2.403c0 .165-.122.298-.273.298ZM7.341 15.76c-.15 0-.273-.132-.273-.297v-2.581c0-.165.122-.297.273-.297.151 0 .273.132.273.297v2.581c0 .165-.122.298-.273.298ZM15.288 14.912c-.15 0-.272-.133-.272-.298v-2.403c0-.164.121-.297.272-.297.151 0 .273.133.273.297v2.403c0 .165-.122.298-.273.298ZM12.577 15.76c-.15 0-.272-.132-.272-.297v-2.581c0-.165.122-.297.272-.297.151 0 .273.132.273.297v2.581c0 .165-.122.298-.273.298ZM10.027 16c-.151 0-.273-.133-.273-.298v-2.294c0-.164.122-.297.273-.297.15 0 .272.133.272.297v2.294c0 .165-.121.297-.272.297Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M6.026 8.964C3.768 8.964 2 7.874 2 6.482 2 5.09 3.767 4 6.026 4c2.258 0 4.025 1.09 4.025 2.482 0 1.392-1.767 2.482-4.026 2.482Zm0-4.37c-1.886 0-3.48.865-3.48 1.888S4.137 8.37 6.025 8.37c1.887 0 3.48-.865 3.48-1.888s-1.593-1.887-3.48-1.887Z"
    />
  </svg>
)
