import { di } from "@di"
import { useEffect } from "react"

export const AnalyticsIdentityBridge = () => {
  const { id } = di.useAuth()
  useEffect(() => {
    if (!id) return
    di.analytics.identify(id)
  }, [id])
  return null
}
