import { useGraphQLQuery } from "@data/useGraphQL"
import { classNames } from "@ds/classNames"
import { cabinetsQuery } from "@features/cabinet/graphql/cabinetsQuery"

interface CabinetTagProps {
  className?: string
  cabinetId: unknown
}

export const CabinetTag = ({ className, cabinetId }: CabinetTagProps) => {
  const { data } = useGraphQLQuery({ document: cabinetsQuery })

  const maybeCabinet = data?.data?.cabinets.find((cabinet) => cabinet.id === cabinetId)
  if (!maybeCabinet) {
    return null
  }

  const cabinetName = maybeCabinet.nom

  return (
    <div className={classNames("rounded bg-secondary-blue px-1 py-0.5 text-h7", className)}>
      {cabinetName.toLocaleUpperCase()}
    </div>
  )
}
