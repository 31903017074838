import { graphql } from "@data/gql"

export const streamChatQuery = graphql(`
  query streamChatQuery {
    streamChat {
      token
      userId
    }
  }
`)
