import { DateSeparatorProps } from "stream-chat-react"
import { format, isToday, isYesterday } from "date-fns"
import { fr } from "date-fns/locale"
import { useTranslation } from "react-i18next"

export const CustomDateSeparator = (props: DateSeparatorProps) => {
  const { date } = props
  const { t } = useTranslation()

  let displayedDate = format(date, "EEEE dd/MM", { locale: fr })

  if (isToday(date)) {
    displayedDate = t("aujourdhui")
  } else if (isYesterday(date)) {
    displayedDate = t("hier")
  }

  return (
    <div className="flex items-center justify-center">
      <div className="mb-2 mt-6 rounded-full bg-extra-light-grey px-2 py-1 text-h7 text-grey">
        {displayedDate.toLocaleUpperCase()}
      </div>
    </div>
  )
}
