import { SVGProps } from "react"
export const Collaboration = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" fill="none" viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 10.333A2.667 2.667 0 1 0 8 5a2.667 2.667 0 0 0 0 5.333ZM8 17c2.577 0 4.666-1.194 4.666-2.667 0-1.472-2.089-2.666-4.666-2.666-2.578 0-4.667 1.194-4.667 2.666C3.333 15.806 5.423 17 8 17ZM16 8.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5c1.933 0 3.5-.895 3.5-2s-1.567-2-3.5-2-3.5.895-3.5 2 1.567 2 3.5 2Z"
      clipRule="evenodd"
    />
  </svg>
)
