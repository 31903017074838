import { useReducer } from "react"

const reducer =
  <U>(select: (val: U) => string) =>
  (state: U[] | null | undefined, passedState: U[] | null | undefined): U[] | null | undefined => {
    if (!state || !passedState) {
      return passedState
    }

    const newState: U[] = []
    const newSuggegstions = [...passedState]

    state.forEach((suggestion) => {
      const newSuggegstionIndex = newSuggegstions.findIndex((s) => select(s) === select(suggestion))
      if (newSuggegstionIndex > -1) {
        newState.push(suggestion)
        newSuggegstions.splice(newSuggegstionIndex, 1)
      }
    })

    return [...newState, ...newSuggegstions]
  }

export const useOrderedStringsState = <U>(select: (val: U) => string) => {
  const [orderedSuggestions, setOrderedSuggestions] = useReducer(reducer(select), null)
  return [orderedSuggestions, setOrderedSuggestions] as const
}
