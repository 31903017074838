import { rendezVousParIdentifiantQuery } from "@data/rendezVous/queries/rendezVousParIdentifiantQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { Cross } from "@ds/icons/Cross"
import { Dialog, DialogPanel } from "@headlessui/react"
import { preconsultationRoute } from "@infra/navigation/router"
import { useRouter } from "@tanstack/react-router"
import { useTranslation } from "react-i18next"

export const QuestionnaireDrawer = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const close = () => router.history.back()
  const { id: identifiant } = preconsultationRoute.useParams()
  const { data } = useGraphQLQuery({ document: rendezVousParIdentifiantQuery, variables: { identifiant } })
  const questionnaire = data?.data?.rendezVousParIdentifiant?.preconsultation.preparation.questionnaire || []
  return (
    <Dialog className="relative z-50" onClose={close} open>
      <div className="fixed inset-0 animate-fadein bg-secondary-black" data-testid="backdrop" onClick={close} />
      <DialogPanel className="fixed bottom-0 right-0 top-0 w-1/2 max-w-[600px] animate-slide-from-right overflow-auto bg-white p-6">
        <div className="flex items-start">
          <h1 className="flex-1 overflow-hidden text-ellipsis text-h1">{t("preparationPatient")}</h1>
          <button onClick={close} className="text-2xl" data-testid="close-button">
            <Cross />
          </button>
        </div>
        <div className="h-10" />
        {questionnaire
          .filter(({ libelleQuestion }) => libelleQuestion != "")
          .map(({ identifiant, libelleQuestion, libelleReponse }, index, list) => (
            <div key={identifiant} className="flex flex-col">
              <h4 className="text-h4">{libelleQuestion}</h4>
              <div className="h-4" />
              <p className="text-p-medium">{libelleReponse}</p>
              {index < list.length - 1 && <div className="m-6 h-[1px] self-stretch bg-extra-light-grey" />}
            </div>
          ))}
      </DialogPanel>
    </Dialog>
  )
}
