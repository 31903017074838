/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  LocalDate: { input: Date; output: Date; }
  OffsetDateTime: { input: Date; output: Date; }
};

export enum ActionChoixMotifConsultation {
  Preanamnese = 'PREANAMNESE',
  PrecisionMotif = 'PRECISION_MOTIF'
}

export type Adresse = {
  __typename?: 'Adresse';
  codePostal: Scalars['String']['output'];
  rue: Scalars['String']['output'];
  ville: Scalars['String']['output'];
};

export type Agent = {
  __typename?: 'Agent';
  id_agent_sendbird: Scalars['String']['output'];
  id_membre_equipe_medicale: Scalars['String']['output'];
  metier: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  photo: Scalars['String']['output'];
};

export type AideALaPreanamnese = {
  __typename?: 'AideALaPreanamnese';
  examensConseilles: Array<ExamenConseille>;
  miseEnForme: SyntheseMiseEnForme;
  suggestions: Array<SuggestionDePreanamnese>;
};

export type Cabinet = {
  __typename?: 'Cabinet';
  adresse: Adresse;
  equipeMedicale: Array<Maybe<MembreEquipeMedicale>>;
  id: Scalars['String']['output'];
  lienPriseDeRendezVous?: Maybe<Scalars['String']['output']>;
  nom: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
};

export type CabinetDuRendezVous = {
  __typename?: 'CabinetDuRendezVous';
  adresse: Adresse;
  featureFlags: Array<Maybe<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  lienPriseDeRendezVous?: Maybe<Scalars['String']['output']>;
  nom: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
};

export type CategorieDeDocument = {
  __typename?: 'CategorieDeDocument';
  id: Scalars['String']['output'];
  nom: Scalars['String']['output'];
};

export enum CategorieDocument {
  Autre = 'AUTRE',
  Certificat = 'CERTIFICAT',
  Courrier = 'COURRIER',
  Inconnue = 'INCONNUE',
  OrdonnanceBiologie = 'ORDONNANCE_BIOLOGIE',
  OrdonnanceImagerie = 'ORDONNANCE_IMAGERIE',
  OrdonnancePharmacie = 'ORDONNANCE_PHARMACIE',
  OrdonnanceSoinsParamedicaux = 'ORDONNANCE_SOINS_PARAMEDICAUX'
}

export type CategoriePrincipale = {
  __typename?: 'CategoriePrincipale';
  nom: Scalars['String']['output'];
};

export type ChoixMotifDeConsultation = {
  __typename?: 'ChoixMotifDeConsultation';
  action?: Maybe<ActionChoixMotifConsultation>;
  id: Scalars['String']['output'];
  motifParentId?: Maybe<Scalars['String']['output']>;
  nom: Scalars['String']['output'];
  question?: Maybe<Scalars['String']['output']>;
};

export type Compte = {
  __typename?: 'Compte';
  email?: Maybe<Scalars['String']['output']>;
  identifiantUtilisateur: Scalars['String']['output'];
  numeroDeTelephone: Scalars['String']['output'];
  profilPrincipal?: Maybe<ProfilPatient>;
};

export type Consultation = {
  __typename?: 'Consultation';
  complete: Scalars['Boolean']['output'];
  conclusion?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<DocumentDeConsultation>>;
  donneesDeSuivi?: Maybe<Array<DonneeDeSuivi>>;
  examen?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interrogatoire?: Maybe<Scalars['String']['output']>;
  motif?: Maybe<Scalars['String']['output']>;
};

export type ConsultationLieeAUnDocument = {
  __typename?: 'ConsultationLieeAUnDocument';
  id: Scalars['String']['output'];
  medecin: Medecin;
  patient: ProfilPatient;
  rendezVous: RendezVousLieAUnDocument;
};

export type Conversation = {
  __typename?: 'Conversation';
  cle_cabinet_sendbird: Scalars['String']['output'];
  identifiant_channel_sendbird: Scalars['String']['output'];
  identifiant_profil_concerne: Scalars['String']['output'];
  identifiant_utilisateur_sendbird: Scalars['String']['output'];
  jeton_acces_utilisateur_sendbird: Scalars['String']['output'];
};

export type ConversationCreee = {
  __typename?: 'ConversationCreee';
  identifiantStreamChat: Scalars['String']['output'];
};

export type CoordonneesDuPatient = {
  __typename?: 'CoordonneesDuPatient';
  email?: Maybe<Scalars['String']['output']>;
  numeroDeTelephone?: Maybe<Scalars['String']['output']>;
};

export type CredentialsSendbird = {
  __typename?: 'CredentialsSendbird';
  accessToken: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Document = {
  __typename?: 'Document';
  categoriePrincipale: CategoriePrincipale;
  consultation?: Maybe<ConsultationLieeAUnDocument>;
  date: Scalars['String']['output'];
  dateDuDocument: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identifiantPatient: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  source: SourceDocument;
};

export type DocumentDeConsultation = {
  __typename?: 'DocumentDeConsultation';
  categorie: CategorieDocument;
  id: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  telechargeable: Scalars['Boolean']['output'];
};

export type DonneeDeSuivi = {
  __typename?: 'DonneeDeSuivi';
  donnee: Scalars['String']['output'];
  unite: Scalars['String']['output'];
  valeur: Scalars['String']['output'];
};

export type DonneesBiometriques = {
  __typename?: 'DonneesBiometriques';
  frequenceCardiaque: FrequenceCardiaque;
  poids: Poids;
  pressionArterielleDiastolique: PressionArterielle;
  pressionArterielleSystolique: PressionArterielle;
  saturationEnOxygene: SaturationEnOxygene;
  taille: Taille;
  temperature: Temperature;
};

export type DonneesBiometriquesInput = {
  frequenceCardiaque: FrequenceCardiaqueInput;
  poids: PoidsInput;
  pressionArterielleDiastolique: PressionArterielleInput;
  pressionArterielleSystolique: PressionArterielleInput;
  saturationEnOxygene: SaturationEnOxygeneInput;
  taille: TailleInput;
  temperature: TemperatureInput;
};

export type ExamenConseille = {
  __typename?: 'ExamenConseille';
  contenu: Scalars['String']['output'];
};

export enum FiltreCategorieDocument {
  Certificat = 'CERTIFICAT',
  Courrier = 'COURRIER',
  Ordonnance = 'ORDONNANCE',
  Tous = 'TOUS'
}

export enum FiltreSourceDesDocuments {
  Doctolib = 'DOCTOLIB',
  Tous = 'TOUS'
}

export type FrequenceCardiaque = {
  __typename?: 'FrequenceCardiaque';
  valeurEnBPM?: Maybe<Scalars['String']['output']>;
};

export type FrequenceCardiaqueInput = {
  valeurEnBPM?: InputMaybe<Scalars['String']['input']>;
};

export type ImportDeDocument = {
  __typename?: 'ImportDeDocument';
  identifiantDeLaCategorie: Scalars['String']['output'];
  identifiantDuDocument: Scalars['String']['output'];
  identifiantDuPatient: Scalars['String']['output'];
};

export type ItemQuestionnaire = {
  __typename?: 'ItemQuestionnaire';
  identifiant: Scalars['String']['output'];
  libelleQuestion: Scalars['String']['output'];
  libelleReponse?: Maybe<Scalars['String']['output']>;
};

export enum LieuDuRendezVous {
  ADomicile = 'A_DOMICILE',
  EnCabinet = 'EN_CABINET',
  EnTeleconsultation = 'EN_TELECONSULTATION'
}

export type Medecin = {
  __typename?: 'Medecin';
  id: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  nom: Scalars['String']['output'];
  nomLibelleLong: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
  specialite?: Maybe<Scalars['String']['output']>;
};

export type MembreEquipeMedicale = {
  __typename?: 'MembreEquipeMedicale';
  cabinets: Array<Cabinet>;
  civilite?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metier_libelle_court?: Maybe<Scalars['String']['output']>;
  metier_libelle_long?: Maybe<Scalars['String']['output']>;
  nom?: Maybe<Scalars['String']['output']>;
  nom_libelle_court: Scalars['String']['output'];
  nom_libelle_long: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  prenom?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  specialite?: Maybe<Scalars['String']['output']>;
};

export type MotifDeConsultation = {
  __typename?: 'MotifDeConsultation';
  id: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  precisionMotif?: Maybe<Scalars['String']['output']>;
};

export type MotifDemande = {
  __typename?: 'MotifDemande';
  id: Scalars['String']['output'];
  libelle: Scalars['String']['output'];
  picto: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  choisirMotifConsultation: Preconsultation;
  commencerPreconsultation: Scalars['Boolean']['output'];
  creerUneConversation: ConversationCreee;
  creerUneConversationAvecLePatient: ConversationCreee;
  demarrerPreanamnese: QuestionDePreanamnese;
  demarrerUneConversation: Conversation;
  enregistrerDonneesBiometriques: DonneesBiometriques;
  enregistrerLeTokenDeNotification: Scalars['Boolean']['output'];
  enregistrerSynthese: Preconsultation;
  enregistrerVaccins: Array<Maybe<Vaccin>>;
  envoyerLaPreconsultationDansDoctolib: Scalars['Boolean']['output'];
  preciserMotifConsultation: Preconsultation;
  repondrePreanamnese: ReponseDePreanamnese;
  selectionnerLeProfilPrincipal: ProfilPatient;
  sinscrire: Compte;
  supprimerLeTokenDeNotification: Scalars['Boolean']['output'];
  validerPreconsultation: Preconsultation;
};


export type MutationChoisirMotifConsultationArgs = {
  identifiantMotif: Scalars['String']['input'];
  identifiantRendezVous: Scalars['String']['input'];
};


export type MutationCommencerPreconsultationArgs = {
  identifiantRendezVous: Scalars['String']['input'];
};


export type MutationCreerUneConversationArgs = {
  identifiantDuCabinet: Scalars['String']['input'];
  identifiantProfil: Scalars['String']['input'];
};


export type MutationCreerUneConversationAvecLePatientArgs = {
  identifiantDuCabinet: Scalars['String']['input'];
  identifiantProfil: Scalars['String']['input'];
};


export type MutationDemarrerPreanamneseArgs = {
  identifiantRendezVous: Scalars['String']['input'];
};


export type MutationDemarrerUneConversationArgs = {
  identifiantProfil: Scalars['String']['input'];
  motifDeLaDemande: Scalars['String']['input'];
};


export type MutationEnregistrerDonneesBiometriquesArgs = {
  donneesBiometriques: DonneesBiometriquesInput;
  identifiantRendezVous: Scalars['String']['input'];
};


export type MutationEnregistrerLeTokenDeNotificationArgs = {
  identifiantDeLInstallation: Scalars['String']['input'];
  plateforme: Plateforme;
  token: Scalars['String']['input'];
};


export type MutationEnregistrerSyntheseArgs = {
  contenu: Scalars['String']['input'];
  identifiantRendezVous: Scalars['String']['input'];
};


export type MutationEnregistrerVaccinsArgs = {
  identifiantRendezVous: Scalars['String']['input'];
  vaccins: Array<VaccinInput>;
};


export type MutationEnvoyerLaPreconsultationDansDoctolibArgs = {
  identifiantRendezVous: Scalars['String']['input'];
};


export type MutationPreciserMotifConsultationArgs = {
  identifiantRendezVous: Scalars['String']['input'];
  precisionMotif: Scalars['String']['input'];
};


export type MutationRepondrePreanamneseArgs = {
  identifiantQuestion: Scalars['String']['input'];
  identifiantRendezVous: Scalars['String']['input'];
  reponse: Scalars['String']['input'];
};


export type MutationSelectionnerLeProfilPrincipalArgs = {
  identifiantProfilPatient: Scalars['String']['input'];
};


export type MutationSinscrireArgs = {
  numeroDeTelephone: Scalars['String']['input'];
};


export type MutationSupprimerLeTokenDeNotificationArgs = {
  token: Scalars['String']['input'];
};


export type MutationValiderPreconsultationArgs = {
  identifiantRendezVous: Scalars['String']['input'];
};

export enum Plateforme {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Poids = {
  __typename?: 'Poids';
  valeurEnKg?: Maybe<Scalars['String']['output']>;
};

export type PoidsInput = {
  valeurEnKg?: InputMaybe<Scalars['String']['input']>;
};

export type Preconsultation = {
  __typename?: 'Preconsultation';
  donneesBiometriques: DonneesBiometriques;
  id?: Maybe<Scalars['String']['output']>;
  motif?: Maybe<MotifDeConsultation>;
  preparation: PreparationDePreconsultation;
  /** @deprecated Field no longer supported */
  statut: StatutDePreparation;
  statutDePreconsultation: StatutDePreconsultation;
  synthese?: Maybe<SyntheseDePreanamnese>;
  vaccins: Array<Vaccin>;
  validee: Scalars['Boolean']['output'];
};

export type PreparationDePreconsultation = {
  __typename?: 'PreparationDePreconsultation';
  questionnaire?: Maybe<Array<ItemQuestionnaire>>;
  statut: StatutDePreparation;
  viaLLM: Scalars['Boolean']['output'];
};

export type PressionArterielle = {
  __typename?: 'PressionArterielle';
  valeurEnMmHg?: Maybe<Scalars['String']['output']>;
};

export type PressionArterielleInput = {
  valeurEnMmHg?: InputMaybe<Scalars['String']['input']>;
};

export type ProfilPatient = {
  __typename?: 'ProfilPatient';
  compte?: Maybe<Compte>;
  dateDeNaissance?: Maybe<Scalars['LocalDate']['output']>;
  id: Scalars['String']['output'];
  lienDoctolib: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  nouveauPatient: Scalars['Boolean']['output'];
  numeroDeTelephone?: Maybe<Scalars['String']['output']>;
  prenom?: Maybe<Scalars['String']['output']>;
  sexe?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  agentsCabinetPrincipal: Array<Agent>;
  aideALaPreanamnese: AideALaPreanamnese;
  cabinet: Cabinet;
  cabinetPrincipal: Cabinet;
  cabinets: Array<Cabinet>;
  categoriesDeDocument: Array<CategorieDeDocument>;
  compte: Compte;
  compteParIdentifiant: Compte;
  /** @deprecated Field no longer supported */
  conversations: Array<Conversation>;
  coordonneesDuPatient?: Maybe<CoordonneesDuPatient>;
  credentialsSendbird?: Maybe<CredentialsSendbird>;
  demandeDImportDeDocument: UrlDImportDeDocument;
  demandeDeTelechargement: Telechargement;
  documents: Array<Document>;
  membreEquipeMedicale: MembreEquipeMedicale;
  membreEquipeMedicaleConnecte: MembreEquipeMedicale;
  motifsDeConsultation: Array<ChoixMotifDeConsultation>;
  motifsDesDemandes: Array<Maybe<MotifDemande>>;
  patientParIdentifiant: ProfilPatient;
  patients?: Maybe<Array<ProfilPatient>>;
  profils: Array<ProfilPatient>;
  rendezVous: Array<RendezVous>;
  rendezVousParCabinet: Array<RendezVous>;
  rendezVousParIdentifiant: RendezVous;
  reponsesRapides: Array<ReponseRapide>;
  streamChat: StreamChat;
  tempsDeReponse: TempsDeReponse;
  vaccins: Array<ReferenceVaccin>;
};


export type QueryAgentsCabinetPrincipalArgs = {
  identifiantProfil: Scalars['String']['input'];
};


export type QueryAideALaPreanamneseArgs = {
  contenu: Scalars['String']['input'];
};


export type QueryCabinetArgs = {
  identifiant: Scalars['String']['input'];
};


export type QueryCabinetPrincipalArgs = {
  identifiantProfil: Scalars['String']['input'];
};


export type QueryCompteParIdentifiantArgs = {
  identifiantPatient: Scalars['String']['input'];
};


export type QueryConversationsArgs = {
  identifiantProfil?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCoordonneesDuPatientArgs = {
  identifiantProfil: Scalars['String']['input'];
};


export type QueryDemandeDImportDeDocumentArgs = {
  extension: Scalars['String']['input'];
  identifiantDeLaCategorie: Scalars['String']['input'];
  identifiantDuDocument: Scalars['String']['input'];
  identifiantDuPatient: Scalars['String']['input'];
  nom: Scalars['String']['input'];
};


export type QueryDemandeDeTelechargementArgs = {
  identifiantDuDocument: Scalars['String']['input'];
};


export type QueryDocumentsArgs = {
  identifiantProfil?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<FiltreSourceDesDocuments>;
  typeDesDocuments?: InputMaybe<FiltreCategorieDocument>;
};


export type QueryMembreEquipeMedicaleArgs = {
  identifiant: Scalars['String']['input'];
};


export type QueryPatientParIdentifiantArgs = {
  identifiant: Scalars['String']['input'];
};


export type QueryPatientsArgs = {
  recherche: Scalars['String']['input'];
  strict?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRendezVousArgs = {
  identifiantProfil?: InputMaybe<Scalars['String']['input']>;
  typeDesRendezVous?: InputMaybe<TypeDeRendezVous>;
};


export type QueryRendezVousParCabinetArgs = {
  dateDeDebut: Scalars['OffsetDateTime']['input'];
  dateDeFin: Scalars['OffsetDateTime']['input'];
  identifiantCabinet: Scalars['String']['input'];
};


export type QueryRendezVousParIdentifiantArgs = {
  identifiant: Scalars['String']['input'];
};


export type QueryReponsesRapidesArgs = {
  identifiantCabinet: Scalars['String']['input'];
};


export type QueryVaccinsArgs = {
  recherche: Scalars['String']['input'];
};

export type QuestionDePreanamnese = {
  __typename?: 'QuestionDePreanamnese';
  id: Scalars['String']['output'];
  libelle: Scalars['String']['output'];
  pourcentageDeProgression: Scalars['Int']['output'];
};

export type ReferenceVaccin = {
  __typename?: 'ReferenceVaccin';
  id: Scalars['String']['output'];
  maladie: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  statut: Scalars['String']['output'];
};

export type RendezVous = {
  __typename?: 'RendezVous';
  cabinet: CabinetDuRendezVous;
  consultation?: Maybe<Consultation>;
  consultationTerminee: Scalars['Boolean']['output'];
  /** @deprecated Field no longer supported */
  dateDeDebut: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  dateDeFin: Scalars['String']['output'];
  dateHeureDeDebut: Scalars['OffsetDateTime']['output'];
  dateHeureDeFin: Scalars['OffsetDateTime']['output'];
  id: Scalars['String']['output'];
  lieu: LieuDuRendezVous;
  medecin: Medecin;
  motif: Scalars['String']['output'];
  nombreDePatientsAvantMoi: Scalars['Int']['output'];
  patient: ProfilPatient;
  patientEnSalleDAttente: Scalars['Boolean']['output'];
  preconsultation: Preconsultation;
  retard: Retard;
  statutDuPatient: StatutPatient;
  /** @deprecated Field no longer supported */
  teleconsultation: Scalars['Boolean']['output'];
  tempsDAttenteEstime: Scalars['Int']['output'];
  typeDeRendezVous: TypeDeRendezVous;
};

export type RendezVousLieAUnDocument = {
  __typename?: 'RendezVousLieAUnDocument';
  dateDeDebut: Scalars['String']['output'];
  dateDeFin: Scalars['String']['output'];
  id: Scalars['String']['output'];
  motif: Scalars['String']['output'];
};

export type ReponseDePreanamnese = {
  __typename?: 'ReponseDePreanamnese';
  question?: Maybe<QuestionDePreanamnese>;
  termine: Scalars['Boolean']['output'];
};

export type ReponseRapide = {
  __typename?: 'ReponseRapide';
  contenu: Scalars['String']['output'];
  id: Scalars['String']['output'];
  titre: Scalars['String']['output'];
};

export type Retard = {
  __typename?: 'Retard';
  avecMargeEnMinutes: Scalars['Int']['output'];
  reelEnMinutes: Scalars['Int']['output'];
};

export enum Role {
  RoleSoignant = 'ROLE_SOIGNANT'
}

export type SaturationEnOxygene = {
  __typename?: 'SaturationEnOxygene';
  valeurEnPourcentage?: Maybe<Scalars['String']['output']>;
};

export type SaturationEnOxygeneInput = {
  valeurEnPourcentage?: InputMaybe<Scalars['String']['input']>;
};

export enum SourceDocument {
  Doctolib = 'DOCTOLIB',
  PrimaryApp = 'PRIMARY_APP'
}

export enum StatutDePreconsultation {
  APreparer = 'A_PREPARER',
  EnCours = 'EN_COURS',
  Terminee = 'TERMINEE'
}

export enum StatutDePreparation {
  APreparer = 'A_PREPARER',
  EnCoursDePreparation = 'EN_COURS_DE_PREPARATION',
  PreparationTerminee = 'PREPARATION_TERMINEE'
}

export enum StatutPatient {
  AuCabinet = 'AU_CABINET',
  EnConsultation = 'EN_CONSULTATION',
  PasAuCabinet = 'PAS_AU_CABINET'
}

export enum StatutSyntheseDePreanamnese {
  EnCoursDeGeneration = 'EN_COURS_DE_GENERATION',
  Generee = 'GENEREE'
}

export type StreamChat = {
  __typename?: 'StreamChat';
  token: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SuggestionDePreanamnese = {
  __typename?: 'SuggestionDePreanamnese';
  contenu: Scalars['String']['output'];
};

export type SyntheseDePreanamnese = {
  __typename?: 'SyntheseDePreanamnese';
  contenu?: Maybe<Scalars['String']['output']>;
  dateDeMiseAJour?: Maybe<Scalars['OffsetDateTime']['output']>;
  miseAJourPar?: Maybe<Scalars['String']['output']>;
  statut: StatutSyntheseDePreanamnese;
};

export type SyntheseMiseEnForme = {
  __typename?: 'SyntheseMiseEnForme';
  contenu: Scalars['String']['output'];
};

export type Taille = {
  __typename?: 'Taille';
  valeurEnCm?: Maybe<Scalars['String']['output']>;
};

export type TailleInput = {
  valeurEnCm?: InputMaybe<Scalars['String']['input']>;
};

export type Telechargement = {
  __typename?: 'Telechargement';
  adresse: Scalars['String']['output'];
};

export type Temperature = {
  __typename?: 'Temperature';
  valeurEnDegresCelcius?: Maybe<Scalars['String']['output']>;
};

export type TemperatureInput = {
  valeurEnDegresCelcius?: InputMaybe<Scalars['String']['input']>;
};

export type TempsDeReponse = {
  __typename?: 'TempsDeReponse';
  parametre?: Maybe<Scalars['String']['output']>;
  reponse: TypeDeReponse;
};

export enum TypeDeRendezVous {
  AVenir = 'A_VENIR',
  Passe = 'PASSE'
}

export enum TypeDeReponse {
  Aujourdhui = 'AUJOURDHUI',
  Date = 'DATE',
  Demain = 'DEMAIN',
  MoinsDeNHeures = 'MOINS_DE_N_HEURES'
}

export type UrlDImportDeDocument = {
  __typename?: 'UrlDImportDeDocument';
  adresse: Scalars['String']['output'];
};

export type Vaccin = {
  __typename?: 'Vaccin';
  dateDExpiration?: Maybe<Scalars['String']['output']>;
  dateDInjection?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  idDoctolib: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  numeroDeLot?: Maybe<Scalars['String']['output']>;
};

export type VaccinInput = {
  dateDExpiration?: InputMaybe<Scalars['String']['input']>;
  dateDInjection?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  idDoctolib: Scalars['String']['input'];
  nom: Scalars['String']['input'];
  numeroDeLot?: InputMaybe<Scalars['String']['input']>;
};

export type Vide = {
  __typename?: 'Vide';
  succes?: Maybe<Scalars['Boolean']['output']>;
};

export type MembreEquipeMedicaleQueryVariables = Exact<{ [key: string]: never; }>;


export type MembreEquipeMedicaleQuery = { __typename?: 'Query', membreEquipeMedicaleConnecte: { __typename?: 'MembreEquipeMedicale', nom_libelle_long: string, photo?: string | null } };

export type CreerUneConversationAvecLePatientMutationMutationVariables = Exact<{
  identifiantProfil: Scalars['String']['input'];
  identifiantDuCabinet: Scalars['String']['input'];
}>;


export type CreerUneConversationAvecLePatientMutationMutation = { __typename?: 'Mutation', creerUneConversationAvecLePatient: { __typename?: 'ConversationCreee', identifiantStreamChat: string } };

export type ReponsesRapidesQueryQueryVariables = Exact<{
  identifiantCabinet: Scalars['String']['input'];
}>;


export type ReponsesRapidesQueryQuery = { __typename?: 'Query', reponsesRapides: Array<{ __typename?: 'ReponseRapide', id: string, titre: string, contenu: string }> };

export type StreamChatQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type StreamChatQueryQuery = { __typename?: 'Query', streamChat: { __typename?: 'StreamChat', token: string, userId: string } };

export type DocumentParIdentifiantQueryQueryVariables = Exact<{
  identifiant: Scalars['String']['input'];
}>;


export type DocumentParIdentifiantQueryQuery = { __typename?: 'Query', demandeDeTelechargement: { __typename?: 'Telechargement', adresse: string } };

export type PatientParIdentifiantQueryQueryVariables = Exact<{
  identifiant: Scalars['String']['input'];
}>;


export type PatientParIdentifiantQueryQuery = { __typename?: 'Query', patientParIdentifiant: { __typename?: 'ProfilPatient', id: string, prenom?: string | null, nom: string, dateDeNaissance?: Date | null, sexe?: string | null, numeroDeTelephone?: string | null, lienDoctolib: string, nouveauPatient: boolean, compte?: { __typename?: 'Compte', profilPrincipal?: { __typename?: 'ProfilPatient', id: string } | null } | null } };

export type PatientsQueryQueryVariables = Exact<{
  recherche: Scalars['String']['input'];
}>;


export type PatientsQueryQuery = { __typename?: 'Query', patients?: Array<{ __typename?: 'ProfilPatient', id: string, prenom?: string | null, nom: string, dateDeNaissance?: Date | null, sexe?: string | null, numeroDeTelephone?: string | null, compte?: { __typename?: 'Compte', email?: string | null, identifiantUtilisateur: string, numeroDeTelephone: string } | null }> | null };

export type AideExamensConseillesQueryVariables = Exact<{
  contenu: Scalars['String']['input'];
}>;


export type AideExamensConseillesQuery = { __typename?: 'Query', aideALaPreanamnese: { __typename?: 'AideALaPreanamnese', examensConseilles: Array<{ __typename?: 'ExamenConseille', contenu: string }> } };

export type AideMettreEnFormeQueryVariables = Exact<{
  contenu: Scalars['String']['input'];
}>;


export type AideMettreEnFormeQuery = { __typename?: 'Query', aideALaPreanamnese: { __typename?: 'AideALaPreanamnese', miseEnForme: { __typename?: 'SyntheseMiseEnForme', contenu: string } } };

export type AideSuggestionsQueryQueryVariables = Exact<{
  contenu: Scalars['String']['input'];
}>;


export type AideSuggestionsQueryQuery = { __typename?: 'Query', aideALaPreanamnese: { __typename?: 'AideALaPreanamnese', suggestions: Array<{ __typename?: 'SuggestionDePreanamnese', contenu: string }> } };

export type CommencerPreconsultationMutationVariables = Exact<{
  identifiantDuRendezVous: Scalars['String']['input'];
}>;


export type CommencerPreconsultationMutation = { __typename?: 'Mutation', commencerPreconsultation: boolean };

export type EnregistrerDonneesBiometriquesMutationVariables = Exact<{
  identifiantDuRendezVous: Scalars['String']['input'];
  donnees: DonneesBiometriquesInput;
}>;


export type EnregistrerDonneesBiometriquesMutation = { __typename?: 'Mutation', enregistrerDonneesBiometriques: { __typename?: 'DonneesBiometriques', frequenceCardiaque: { __typename?: 'FrequenceCardiaque', valeurEnBPM?: string | null } } };

export type EnregistrerSyntheseMutationMutationVariables = Exact<{
  identifiantDuRendezVous: Scalars['String']['input'];
  contenu: Scalars['String']['input'];
}>;


export type EnregistrerSyntheseMutationMutation = { __typename?: 'Mutation', enregistrerSynthese: { __typename?: 'Preconsultation', id?: string | null } };

export type EnregistrerVaccinsMutationVariables = Exact<{
  identifiantDuRendezVous: Scalars['String']['input'];
  vaccins: Array<VaccinInput> | VaccinInput;
}>;


export type EnregistrerVaccinsMutation = { __typename?: 'Mutation', enregistrerVaccins: Array<{ __typename?: 'Vaccin', id: string } | null> };

export type EnvoyerLaPreconsultationDansDoctolibMutationVariables = Exact<{
  identifiantDuRendezVous: Scalars['String']['input'];
}>;


export type EnvoyerLaPreconsultationDansDoctolibMutation = { __typename?: 'Mutation', envoyerLaPreconsultationDansDoctolib: boolean };

export type ValiderPreconsultationMutationMutationVariables = Exact<{
  identifiantDuRendezVous: Scalars['String']['input'];
}>;


export type ValiderPreconsultationMutationMutation = { __typename?: 'Mutation', validerPreconsultation: { __typename?: 'Preconsultation', id?: string | null, validee: boolean } };

export type RechercherVaccinsQueryQueryVariables = Exact<{
  recherche: Scalars['String']['input'];
}>;


export type RechercherVaccinsQueryQuery = { __typename?: 'Query', vaccins: Array<{ __typename?: 'ReferenceVaccin', id: string, nom: string }> };

export type RendezVousQueryQueryVariables = Exact<{
  identifiantProfil: Scalars['String']['input'];
}>;


export type RendezVousQueryQuery = { __typename?: 'Query', rendezVous: Array<{ __typename?: 'RendezVous', typeDeRendezVous: TypeDeRendezVous, id: string, dateHeureDeDebut: Date, dateHeureDeFin: Date, motif: string, patientEnSalleDAttente: boolean, lieu: LieuDuRendezVous, consultationTerminee: boolean, patient: { __typename?: 'ProfilPatient', id: string, prenom?: string | null, nom: string, sexe?: string | null, dateDeNaissance?: Date | null, numeroDeTelephone?: string | null, lienDoctolib: string, compte?: { __typename?: 'Compte', identifiantUtilisateur: string, numeroDeTelephone: string, email?: string | null } | null }, medecin: { __typename?: 'Medecin', nom: string, photo?: string | null }, consultation?: { __typename?: 'Consultation', complete: boolean } | null, preconsultation: { __typename?: 'Preconsultation', validee: boolean, motif?: { __typename?: 'MotifDeConsultation', nom: string } | null, preparation: { __typename?: 'PreparationDePreconsultation', statut: StatutDePreparation, viaLLM: boolean } } }> };

export type RendezVousParCabinetQueryQueryVariables = Exact<{
  dateDeDebut: Scalars['OffsetDateTime']['input'];
  dateDeFin: Scalars['OffsetDateTime']['input'];
  identifiantCabinet: Scalars['String']['input'];
}>;


export type RendezVousParCabinetQueryQuery = { __typename?: 'Query', rendezVousParCabinet: Array<{ __typename?: 'RendezVous', id: string, dateHeureDeDebut: Date, dateHeureDeFin: Date, motif: string, consultationTerminee: boolean, patientEnSalleDAttente: boolean, lieu: LieuDuRendezVous, patient: { __typename?: 'ProfilPatient', prenom?: string | null, nom: string, compte?: { __typename?: 'Compte', identifiantUtilisateur: string, numeroDeTelephone: string, email?: string | null } | null }, medecin: { __typename?: 'Medecin', nom: string, photo?: string | null }, preconsultation: { __typename?: 'Preconsultation', validee: boolean, motif?: { __typename?: 'MotifDeConsultation', nom: string } | null, preparation: { __typename?: 'PreparationDePreconsultation', statut: StatutDePreparation, viaLLM: boolean } } }> };

export type RendezVousParIdentifiantQueryVariables = Exact<{
  identifiant: Scalars['String']['input'];
}>;


export type RendezVousParIdentifiantQuery = { __typename?: 'Query', rendezVousParIdentifiant: { __typename?: 'RendezVous', id: string, dateHeureDeDebut: Date, motif: string, patient: { __typename?: 'ProfilPatient', id: string, prenom?: string | null, nom: string, sexe?: string | null, dateDeNaissance?: Date | null, lienDoctolib: string, nouveauPatient: boolean, compte?: { __typename?: 'Compte', identifiantUtilisateur: string, numeroDeTelephone: string, email?: string | null } | null }, preconsultation: { __typename?: 'Preconsultation', statutDePreconsultation: StatutDePreconsultation, validee: boolean, preparation: { __typename?: 'PreparationDePreconsultation', statut: StatutDePreparation, viaLLM: boolean, questionnaire?: Array<{ __typename?: 'ItemQuestionnaire', identifiant: string, libelleQuestion: string, libelleReponse?: string | null }> | null }, motif?: { __typename?: 'MotifDeConsultation', nom: string, precisionMotif?: string | null } | null, synthese?: { __typename?: 'SyntheseDePreanamnese', statut: StatutSyntheseDePreanamnese, contenu?: string | null, dateDeMiseAJour?: Date | null, miseAJourPar?: string | null } | null, donneesBiometriques: { __typename?: 'DonneesBiometriques', pressionArterielleSystolique: { __typename?: 'PressionArterielle', valeurEnMmHg?: string | null }, pressionArterielleDiastolique: { __typename?: 'PressionArterielle', valeurEnMmHg?: string | null }, temperature: { __typename?: 'Temperature', valeurEnDegresCelcius?: string | null }, frequenceCardiaque: { __typename?: 'FrequenceCardiaque', valeurEnBPM?: string | null }, taille: { __typename?: 'Taille', valeurEnCm?: string | null }, poids: { __typename?: 'Poids', valeurEnKg?: string | null }, saturationEnOxygene: { __typename?: 'SaturationEnOxygene', valeurEnPourcentage?: string | null } }, vaccins: Array<{ __typename?: 'Vaccin', id: string, idDoctolib: string, nom: string, numeroDeLot?: string | null, dateDExpiration?: string | null, dateDInjection?: string | null }> } } };

export type CabinetsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type CabinetsQueryQuery = { __typename?: 'Query', cabinets: Array<{ __typename?: 'Cabinet', id: string, nom: string }> };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const MembreEquipeMedicaleDocument = new TypedDocumentString(`
    query membreEquipeMedicale {
  membreEquipeMedicaleConnecte {
    nom_libelle_long
    photo
  }
}
    `) as unknown as TypedDocumentString<MembreEquipeMedicaleQuery, MembreEquipeMedicaleQueryVariables>;
export const CreerUneConversationAvecLePatientMutationDocument = new TypedDocumentString(`
    mutation creerUneConversationAvecLePatientMutation($identifiantProfil: String!, $identifiantDuCabinet: String!) {
  creerUneConversationAvecLePatient(
    identifiantProfil: $identifiantProfil
    identifiantDuCabinet: $identifiantDuCabinet
  ) {
    identifiantStreamChat
  }
}
    `) as unknown as TypedDocumentString<CreerUneConversationAvecLePatientMutationMutation, CreerUneConversationAvecLePatientMutationMutationVariables>;
export const ReponsesRapidesQueryDocument = new TypedDocumentString(`
    query reponsesRapidesQuery($identifiantCabinet: String!) {
  reponsesRapides(identifiantCabinet: $identifiantCabinet) {
    id
    titre
    contenu
  }
}
    `) as unknown as TypedDocumentString<ReponsesRapidesQueryQuery, ReponsesRapidesQueryQueryVariables>;
export const StreamChatQueryDocument = new TypedDocumentString(`
    query streamChatQuery {
  streamChat {
    token
    userId
  }
}
    `) as unknown as TypedDocumentString<StreamChatQueryQuery, StreamChatQueryQueryVariables>;
export const DocumentParIdentifiantQueryDocument = new TypedDocumentString(`
    query documentParIdentifiantQuery($identifiant: String!) {
  demandeDeTelechargement(identifiantDuDocument: $identifiant) {
    adresse
  }
}
    `) as unknown as TypedDocumentString<DocumentParIdentifiantQueryQuery, DocumentParIdentifiantQueryQueryVariables>;
export const PatientParIdentifiantQueryDocument = new TypedDocumentString(`
    query patientParIdentifiantQuery($identifiant: String!) {
  patientParIdentifiant(identifiant: $identifiant) {
    id
    prenom
    nom
    dateDeNaissance
    sexe
    numeroDeTelephone
    compte {
      profilPrincipal {
        id
      }
    }
    lienDoctolib
    nouveauPatient
  }
}
    `) as unknown as TypedDocumentString<PatientParIdentifiantQueryQuery, PatientParIdentifiantQueryQueryVariables>;
export const PatientsQueryDocument = new TypedDocumentString(`
    query patientsQuery($recherche: String!) {
  patients(recherche: $recherche) {
    id
    prenom
    nom
    dateDeNaissance
    sexe
    numeroDeTelephone
    compte {
      email
      identifiantUtilisateur
      numeroDeTelephone
    }
  }
}
    `) as unknown as TypedDocumentString<PatientsQueryQuery, PatientsQueryQueryVariables>;
export const AideExamensConseillesDocument = new TypedDocumentString(`
    query aideExamensConseilles($contenu: String!) {
  aideALaPreanamnese(contenu: $contenu) {
    examensConseilles {
      contenu
    }
  }
}
    `) as unknown as TypedDocumentString<AideExamensConseillesQuery, AideExamensConseillesQueryVariables>;
export const AideMettreEnFormeDocument = new TypedDocumentString(`
    query aideMettreEnForme($contenu: String!) {
  aideALaPreanamnese(contenu: $contenu) {
    miseEnForme {
      contenu
    }
  }
}
    `) as unknown as TypedDocumentString<AideMettreEnFormeQuery, AideMettreEnFormeQueryVariables>;
export const AideSuggestionsQueryDocument = new TypedDocumentString(`
    query aideSuggestionsQuery($contenu: String!) {
  aideALaPreanamnese(contenu: $contenu) {
    suggestions {
      contenu
    }
  }
}
    `) as unknown as TypedDocumentString<AideSuggestionsQueryQuery, AideSuggestionsQueryQueryVariables>;
export const CommencerPreconsultationDocument = new TypedDocumentString(`
    mutation commencerPreconsultation($identifiantDuRendezVous: String!) {
  commencerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous)
}
    `) as unknown as TypedDocumentString<CommencerPreconsultationMutation, CommencerPreconsultationMutationVariables>;
export const EnregistrerDonneesBiometriquesDocument = new TypedDocumentString(`
    mutation enregistrerDonneesBiometriques($identifiantDuRendezVous: String!, $donnees: DonneesBiometriquesInput!) {
  enregistrerDonneesBiometriques(
    identifiantRendezVous: $identifiantDuRendezVous
    donneesBiometriques: $donnees
  ) {
    frequenceCardiaque {
      valeurEnBPM
    }
  }
}
    `) as unknown as TypedDocumentString<EnregistrerDonneesBiometriquesMutation, EnregistrerDonneesBiometriquesMutationVariables>;
export const EnregistrerSyntheseMutationDocument = new TypedDocumentString(`
    mutation enregistrerSyntheseMutation($identifiantDuRendezVous: String!, $contenu: String!) {
  enregistrerSynthese(
    identifiantRendezVous: $identifiantDuRendezVous
    contenu: $contenu
  ) {
    id
  }
}
    `) as unknown as TypedDocumentString<EnregistrerSyntheseMutationMutation, EnregistrerSyntheseMutationMutationVariables>;
export const EnregistrerVaccinsDocument = new TypedDocumentString(`
    mutation enregistrerVaccins($identifiantDuRendezVous: String!, $vaccins: [VaccinInput!]!) {
  enregistrerVaccins(
    identifiantRendezVous: $identifiantDuRendezVous
    vaccins: $vaccins
  ) {
    id
  }
}
    `) as unknown as TypedDocumentString<EnregistrerVaccinsMutation, EnregistrerVaccinsMutationVariables>;
export const EnvoyerLaPreconsultationDansDoctolibDocument = new TypedDocumentString(`
    mutation envoyerLaPreconsultationDansDoctolib($identifiantDuRendezVous: String!) {
  envoyerLaPreconsultationDansDoctolib(
    identifiantRendezVous: $identifiantDuRendezVous
  )
}
    `) as unknown as TypedDocumentString<EnvoyerLaPreconsultationDansDoctolibMutation, EnvoyerLaPreconsultationDansDoctolibMutationVariables>;
export const ValiderPreconsultationMutationDocument = new TypedDocumentString(`
    mutation validerPreconsultationMutation($identifiantDuRendezVous: String!) {
  validerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous) {
    id
    validee
  }
}
    `) as unknown as TypedDocumentString<ValiderPreconsultationMutationMutation, ValiderPreconsultationMutationMutationVariables>;
export const RechercherVaccinsQueryDocument = new TypedDocumentString(`
    query rechercherVaccinsQuery($recherche: String!) {
  vaccins(recherche: $recherche) {
    id
    nom
  }
}
    `) as unknown as TypedDocumentString<RechercherVaccinsQueryQuery, RechercherVaccinsQueryQueryVariables>;
export const RendezVousQueryDocument = new TypedDocumentString(`
    query rendezVousQuery($identifiantProfil: String!) {
  rendezVous(identifiantProfil: $identifiantProfil) {
    typeDeRendezVous
    id
    dateHeureDeDebut
    dateHeureDeFin
    motif
    patient {
      id
      prenom
      nom
      sexe
      dateDeNaissance
      numeroDeTelephone
      compte {
        identifiantUtilisateur
        numeroDeTelephone
        email
      }
      lienDoctolib
    }
    medecin {
      nom
      photo
    }
    consultation {
      complete
    }
    preconsultation {
      motif {
        nom
      }
      preparation {
        statut
        viaLLM
      }
      validee
    }
    patientEnSalleDAttente
    lieu
    consultationTerminee
  }
}
    `) as unknown as TypedDocumentString<RendezVousQueryQuery, RendezVousQueryQueryVariables>;
export const RendezVousParCabinetQueryDocument = new TypedDocumentString(`
    query rendezVousParCabinetQuery($dateDeDebut: OffsetDateTime!, $dateDeFin: OffsetDateTime!, $identifiantCabinet: String!) {
  rendezVousParCabinet(
    dateDeDebut: $dateDeDebut
    dateDeFin: $dateDeFin
    identifiantCabinet: $identifiantCabinet
  ) {
    id
    dateHeureDeDebut
    dateHeureDeFin
    motif
    patient {
      prenom
      nom
      compte {
        identifiantUtilisateur
        numeroDeTelephone
        email
      }
    }
    consultationTerminee
    medecin {
      nom
      photo
    }
    preconsultation {
      motif {
        nom
      }
      preparation {
        statut
        viaLLM
      }
      validee
    }
    patientEnSalleDAttente
    lieu
  }
}
    `) as unknown as TypedDocumentString<RendezVousParCabinetQueryQuery, RendezVousParCabinetQueryQueryVariables>;
export const RendezVousParIdentifiantDocument = new TypedDocumentString(`
    query RendezVousParIdentifiant($identifiant: String!) {
  rendezVousParIdentifiant(identifiant: $identifiant) {
    id
    dateHeureDeDebut
    motif
    patient {
      id
      prenom
      nom
      sexe
      dateDeNaissance
      compte {
        identifiantUtilisateur
        numeroDeTelephone
        email
      }
      lienDoctolib
      nouveauPatient
    }
    preconsultation {
      statutDePreconsultation
      preparation {
        statut
        viaLLM
        questionnaire {
          identifiant
          libelleQuestion
          libelleReponse
        }
      }
      validee
      motif {
        nom
        precisionMotif
      }
      synthese {
        statut
        contenu
        dateDeMiseAJour
        miseAJourPar
      }
      donneesBiometriques {
        pressionArterielleSystolique {
          valeurEnMmHg
        }
        pressionArterielleDiastolique {
          valeurEnMmHg
        }
        temperature {
          valeurEnDegresCelcius
        }
        frequenceCardiaque {
          valeurEnBPM
        }
        taille {
          valeurEnCm
        }
        poids {
          valeurEnKg
        }
        saturationEnOxygene {
          valeurEnPourcentage
        }
      }
      vaccins {
        id
        idDoctolib
        nom
        numeroDeLot
        dateDExpiration
        dateDInjection
      }
    }
  }
}
    `) as unknown as TypedDocumentString<RendezVousParIdentifiantQuery, RendezVousParIdentifiantQueryVariables>;
export const CabinetsQueryDocument = new TypedDocumentString(`
    query cabinetsQuery {
  cabinets {
    id
    nom
  }
}
    `) as unknown as TypedDocumentString<CabinetsQueryQuery, CabinetsQueryQueryVariables>;
