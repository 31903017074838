import { SVGProps } from "react"

export const MagnifyingGlass = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="12.4453"
      y="10.6205"
      width="9.23876"
      height="2.61176"
      rx="1.30588"
      transform="rotate(45 12.4453 10.6205)"
      fill="currentColor"
      stroke="none"
    />
    <circle
      cx="8.60098"
      cy="8.57865"
      r="6.18799"
      transform="rotate(165 8.60098 8.57865)"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M11.1444 5.18175C11.1007 5.14105 11.0342 5.11225 10.983 5.08024C10.8093 4.97171 10.6446 4.84825 10.4708 4.74098C10.1449 4.53981 9.73766 4.47909 9.36194 4.4105C9.21251 4.38323 9.0687 4.3546 8.9168 4.33626C8.78291 4.3201 8.65116 4.30903 8.51608 4.3096C8.41869 4.31002 8.27708 4.27612 8.18326 4.29596C7.88286 4.35951 7.54207 4.31334 7.25404 4.42745C7.06051 4.50413 6.8641 4.56398 6.67634 4.65204C6.52835 4.72145 6.41309 4.8164 6.27881 4.90802C6.08108 5.04295 5.90125 5.16207 5.73859 5.33375C5.56528 5.51667 5.29805 5.74403 5.17243 5.98655"
      strokeLinecap="round"
    />
    <path
      d="M12.9617 7.80985C12.9128 7.5755 12.8717 7.36376 12.7754 7.14773C12.6729 6.91756 12.5404 6.62375 12.3159 6.49724"
      strokeLinecap="round"
    />
  </svg>
)
