import { BloodPressure } from "@ds/icons/BloodPressure"
import { Thermometer } from "@ds/icons/Thermometer"
import { HeartCardiogram } from "@ds/icons/HeartCadiogram"
import { ReactNode } from "react"
import { Weight } from "@ds/icons/Weight"
import { Height } from "@ds/icons/Height"
import { PulseOximeter } from "@ds/icons/PulseOximeter"

export const donneesBiometriquesStatiques: {
  code: string
  nom: string
  valeur?: string | number
  unite: string
  icone: ReactNode
  suppression?: boolean
}[] = [
  { code: "PAS", nom: "PAS", valeur: undefined, unite: "mmHg", icone: <BloodPressure className="text-h4" /> },
  { code: "PAD", nom: "PAD", valeur: undefined, unite: "mmHg", icone: <BloodPressure className="text-h4" /> },
  { code: "FC", nom: "FC", valeur: undefined, unite: "bpm", icone: <HeartCardiogram className="text-h4" /> },
  { code: "Temp", nom: "Temp", valeur: undefined, unite: "°C", icone: <Thermometer className="text-h4" /> },
  {
    code: "Poids",
    nom: "Poids",
    valeur: undefined,
    unite: "kg",
    icone: <Weight className="text-h4" />,
    suppression: true,
  },
  {
    code: "Taille",
    nom: "Taille",
    valeur: undefined,
    unite: "cm",
    icone: <Height className="text-h4" />,
    suppression: true,
  },
  {
    code: "SpO2",
    nom: "SpO2",
    valeur: undefined,
    unite: "%",
    icone: <PulseOximeter className="text-h4" />,
    suppression: true,
  },
]
