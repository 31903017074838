import { useChatContext } from "stream-chat-react"

export const useConnectedToChat = () => {
  const { client } = useChatContext()

  return {
    isConnected: !!client.user,
    identifiantDeLUtilisateur: client.user?.id,
  }
}
