import { SVGProps } from "react"
export const Flag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill={props.color} viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 2.572a.75.75 0 0 1 .75.75v.25H15a2 2 0 0 1 2 2v7.75c0 .69-.56 1.25-1.25 1.25H4.5v7.25a.75.75 0 0 1-1.5 0v-18.5a.75.75 0 0 1 .75-.75Zm3.25 14v-.5h8.75a2.75 2.75 0 0 0 2.75-2.75v-5.75h.5a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2Z"
      clipRule="evenodd"
    />
  </svg>
)
