import { EventComponentProps } from "stream-chat-react"
import { useEffect, useRef } from "react"

export const CustomSystemMessage = (props: EventComponentProps) => {
  const { message } = props
  const messageDiv = useRef<HTMLDivElement>(null)

  const { event } = message

  useEffect(() => {
    if (messageDiv.current?.parentElement?.parentElement) {
      const messagesList = messageDiv.current.parentElement.parentElement
      messagesList.scrollBy({ top: messagesList.scrollHeight, behavior: "smooth" })
    }
  }, [message])

  return (
    event &&
    message.text && (
      <div ref={messageDiv} className="my-2 flex items-center justify-center">
        <div className="rounded-full bg-extra-light-grey px-2 py-1 text-h7 text-grey">{message.text.toUpperCase()}</div>
      </div>
    )
  )
}
