import { graphql } from "@data/gql"
import { AideExamensConseillesQuery } from "@data/gql/graphql"

export type ExamenConseilleAPI = AideExamensConseillesQuery["aideALaPreanamnese"]["examensConseilles"][number]

export const aideExamensConseilles = graphql(`
  query aideExamensConseilles($contenu: String!) {
    aideALaPreanamnese(contenu: $contenu) {
      examensConseilles {
        contenu
      }
    }
  }
`)
