import mixpanel from "mixpanel-browser"
import { Analytics } from "./Analytics"

export class MixpanelAnalytics implements Analytics {
  constructor(token: string = import.meta.env.VITE_MIXPANEL_TOKEN) {
    mixpanel.init(token, {
      ignore_dnt: true,
      track_pageview: false,
      persistence: "localStorage",
    })
  }

  trackEvent(event: string, data: Record<string, unknown>) {
    mixpanel.track(event, data)
  }

  identify(userId: string) {
    mixpanel.identify(userId)
  }
}
