import { SVGProps } from "react"

export const Cross = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g clipPath="url(#a)">
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.3} clipPath="url(#b)">
        <path d="M14.333 4.048 3.669 14.712M14.331 14.712 3.667 4.048" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
