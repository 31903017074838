import { TypeDeRendezVous } from "@data/gql/graphql"
import { format, isBefore } from "date-fns"
import { fr } from "date-fns/locale"

type ObjectAvecTypeDeRendezVous = {
  dateHeureDeDebut: Date
  typeDeRendezVous: TypeDeRendezVous
}

export const grouperParTypeDeRendezVous = <T extends ObjectAvecTypeDeRendezVous>(input: T[]) => {
  const result: { [key: string]: { [key: string]: T[] } } = {}
  input.sort((rdv1, rdv2) => (isBefore(rdv1.dateHeureDeDebut, rdv2.dateHeureDeDebut) ? 1 : -1))

  input.forEach((rdv) => {
    const { typeDeRendezVous, dateHeureDeDebut } = rdv

    const mois = format(dateHeureDeDebut, "MMMM yyyy", { locale: fr }).toUpperCase()

    result[typeDeRendezVous] = result[typeDeRendezVous] || {}
    result[typeDeRendezVous][mois] = result[typeDeRendezVous][mois] || []
    result[typeDeRendezVous][mois].push(rdv)
  })
  return result
}
