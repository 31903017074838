import { useGraphQLQuery } from "@data/useGraphQL.ts"
import { documentParIdentifiantQuery } from "@data/patient/documentParIdentifiantQuery.ts"
import { useEffect, useState } from "react"
import { Document, Page } from "react-pdf"
import { DocumentParIdentifiantQueryQuery } from "@data/gql/graphql.ts"
import { ExecutionResult } from "graphql"
import { QueryObserverResult } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

export const PrevisualisationDocument = ({
  identifiantDuDocument,
  type,
  nom,
}: {
  identifiantDuDocument: string
  type: string
  nom: string
}) => {
  const { t } = useTranslation()
  const [adresse, setAdresse] = useState<string | undefined>()
  const [pdfProtegeParMotDePasse, setPdfProtegeParMotDePasse] = useState(false)
  const { data: document, refetch } = useGraphQLQuery({
    document: documentParIdentifiantQuery,
    variables: { identifiant: identifiantDuDocument },
    staleTime: 45000,
  })
  useEffect(() => {
    setAdresse(document?.data?.demandeDeTelechargement?.adresse)
  }, [document?.data?.demandeDeTelechargement?.adresse])
  const openDocument = () => {
    refetch().then((res: QueryObserverResult<ExecutionResult<DocumentParIdentifiantQueryQuery>> | undefined) => {
      if (adresse) {
        window.open(res?.data?.data?.demandeDeTelechargement?.adresse, "_blank")
      }
    })
  }
  if (type === "jpg" || type === "jpeg" || type === "png") {
    return (
      <div
        // FIXME: width 102% to fix
        className="relative ml-[-4px] mr-[-4px] h-80 w-[102%] max-w-[102%] cursor-pointer overflow-hidden rounded"
        onClick={openDocument}
        title={nom}
      >
        <div
          className="absolute -bottom-4 -left-4 -right-4 -top-4 bg-cover blur-lg"
          style={{ backgroundImage: `url(${adresse})` }}
        />
        <div
          className="absolute bottom-0 left-0 right-0 top-0 bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${adresse})` }}
        />
      </div>
    )
  }
  if (type === "pdf") {
    return (
      <div className="h80 w-full" title={nom} onClick={openDocument}>
        {adresse && (
          <>
            {!pdfProtegeParMotDePasse && (
              <Document
                className="h-80 w-full cursor-pointer overflow-hidden rounded"
                file={adresse}
                onLoadSuccess={() => {}}
                onPassword={() => {
                  setPdfProtegeParMotDePasse(true)
                }}
                loading={
                  <div className="flex h-80 w-full items-center justify-center">{t("messagerie.chargementPdf")}</div>
                }
                error={
                  <div className="flex h-80 w-full items-center justify-center">
                    {t("messagerie.chargementPdfEnErreur")}
                  </div>
                }
              >
                <Page pageNumber={1} width={424} className="h-80" />
              </Document>
            )}
            {pdfProtegeParMotDePasse && (
              <div className="flex h-80 w-full cursor-pointer items-center justify-center rounded bg-dark-plum text-white">
                {t("messagerie.pdfProtege")}
              </div>
            )}
          </>
        )}
      </div>
    )
  }
  return <></>
}
