import { SVGProps } from "react"

export const Bell = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4617 2.76747C7.84629 2.61363 8.28277 2.80069 8.4366 3.18528C8.59044 3.56987 8.40338 4.00635 8.01879 4.16018L7.85156 4.22707C6.54571 4.74941 5.50405 5.77387 4.96002 7.07083L4.88322 7.25393C4.723 7.6359 4.28347 7.81566 3.9015 7.65544C3.51952 7.49522 3.33976 7.05569 3.49998 6.67372L3.57678 6.49062C4.27624 4.82309 5.61553 3.50594 7.29448 2.83436L7.4617 2.76747ZM16.5382 2.81165C16.1536 2.65782 15.7172 2.84488 15.5633 3.22947C15.4095 3.61406 15.5966 4.05053 15.9811 4.20437L16.1484 4.27126C17.4542 4.7936 18.4959 5.81806 19.0399 7.11502L19.1167 7.29812C19.2769 7.68009 19.7165 7.85985 20.0984 7.69963C20.4804 7.53941 20.6602 7.09988 20.5 6.71791L20.4232 6.53481C19.7237 4.86728 18.3844 3.55013 16.7055 2.87854L16.5382 2.81165ZM17.7772 18.7676C19.6055 18.7676 20.6492 16.9285 19.5522 15.6397C19.0665 15.0692 18.7672 14.3925 18.6867 13.6828L18.2395 9.74276C17.9984 7.61821 16.4123 5.87477 14.2699 5.14124V5.03752C14.2699 3.78387 13.2536 2.76758 12 2.76758C10.7463 2.76758 9.73002 3.78387 9.73002 5.03752V5.14124C7.58762 5.87477 6.00156 7.61821 5.76043 9.74276L5.31324 13.6828C5.2327 14.3925 4.93341 15.0692 4.44775 15.6397C3.35072 16.9285 4.3944 18.7676 6.22278 18.7676H17.7772ZM14.9721 20.8391C14.5147 21.9668 13.3565 22.7676 12 22.7676C10.6434 22.7676 9.48525 21.9668 9.02787 20.8391C9.0088 20.7921 8.99997 20.7416 8.99997 20.6908C8.99997 20.4569 9.18957 20.2673 9.42346 20.2673H14.5765C14.8104 20.2673 15 20.4569 15 20.6908C15 20.7416 14.9911 20.7921 14.9721 20.8391Z"
      fill="currentColor"
    />
  </svg>
)
