import { usePreferences } from "@infra/preferences/usePreferences"
import { useGraphQLQuery } from "../../useGraphQL"
import { rendezVousParCabinetQuery } from "../queries/rendezVousParCabinetQuery"
import { addDays, isToday, startOfDay } from "date-fns"

export type UseRendezVousParCabinetProps = {
  date: Date
}

export const useRendezVousParCabinet = ({ date }: UseRendezVousParCabinetProps) => {
  const { identifiantDuCabinet } = usePreferences()

  const { data } = useGraphQLQuery({
    document: rendezVousParCabinetQuery,
    variables: {
      identifiantCabinet: identifiantDuCabinet || "",
      dateDeDebut: startOfDay(date),
      dateDeFin: startOfDay(addDays(date, 1)),
    },
    refetchInterval: isToday(date) ? 5000 : undefined, //refetch toutes les 5 secondes si les données sont du jour
    enabled: identifiantDuCabinet != undefined,
  })

  if (identifiantDuCabinet == undefined || data?.data?.rendezVousParCabinet == undefined) {
    return {
      data: [],
      count: 0,
    }
  }

  return {
    data: data.data.rendezVousParCabinet,
    count: data.data.rendezVousParCabinet.length,
  }
}
