import { Avatar } from "@ds/avatar/Avatar.tsx"
import { ArrowLeft } from "@ds/icons/ArrowLeft"
import { Dot } from "@ds/icons/Dot"
import { agePatient } from "@features/patient/agePatient"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { PrimaryApp } from "@ds/icons/PrimaryApp.tsx"
import { Doctolib } from "@ds/icons/Doctolib.tsx"

interface CompteProps {
  identifiantUtilisateur: string
  numeroDeTelephone: string
  email?: string | null | undefined
}

interface PatientProps {
  id: string
  prenom?: string | null | undefined
  nom: string
  sexe?: string | null | undefined
  dateDeNaissance?: Date | null | undefined
  numeroDeTelephone?: string | null | undefined
  compte?: CompteProps | null | undefined
  lienDoctolib?: string
}

interface EntetePatientProps {
  patient: PatientProps
  onBack?: () => void
  info?: ReactNode
}

export const EntetePatient = ({ patient, onBack, info }: EntetePatientProps) => {
  const { t } = useTranslation()
  const patientPossedeLApplication = patient.compte
  return (
    <div className="flex flex-col gap-4 border-b border-light-grey py-6" data-testid={"entete-patient"}>
      <div className="flex items-center gap-4">
        <div className="cursor-pointer py-1 text-2xl text-black" onClick={onBack} data-testid="back-to-arrow">
          <ArrowLeft />
        </div>
        <Avatar name={patient.prenom + " " + patient.nom} />

        <span className="text-h2">
          {patient.prenom} {patient.nom}
        </span>
        {info && info}
      </div>
      <div className="flex items-center gap-2">
        <span className="text-h5">{patient.sexe && t(patient.sexe)}</span>
        {patient.dateDeNaissance && (
          <>
            <Dot />
            <span className="text-h5">
              {patient.dateDeNaissance && agePatient(patient.dateDeNaissance) + " " + t("ans")}
            </span>
          </>
        )}
        {patient.compte && patient.compte.numeroDeTelephone && (
          <>
            <Dot />
            <span className="text-h5">{patient.compte.numeroDeTelephone}</span>
          </>
        )}
        {patientPossedeLApplication && (
          <>
            <Dot />
            <PrimaryApp />
          </>
        )}
        {patient.lienDoctolib && patient.lienDoctolib.trim() !== "" && (
          <>
            <Dot />
            <a data-testid="lien-doctolib-patient" target="_blank" href={patient.lienDoctolib}>
              <Doctolib className="text-h3" />
            </a>
          </>
        )}
      </div>
    </div>
  )
}
