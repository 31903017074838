import { SVGProps } from "react"

export const BloodPressure = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path fill="currentColor" d="m10.416 8.958-.625 2.084.625.833.625-.833-.625-2.084Z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.167 7.347a7.114 7.114 0 0 1-1.086-.673c-.643-.488-1.414-1.279-1.414-2.32 0-.928.701-1.854 1.745-1.854.484 0 .876.193 1.171.482a1.64 1.64 0 0 1 1.172-.482c1.043 0 1.745.926 1.745 1.853 0 1.01-.776 1.802-1.412 2.293A7.522 7.522 0 0 1 15 7.341c.003.726.014 1.42.026 2.077.024 1.44.046 2.702-.042 3.744-.132 1.578-.524 2.845-1.693 3.53-1.151.674-2.851.927-4.259.756-.707-.086-1.381-.283-1.891-.624-.372-.248-.666-.582-.805-1-.865-.04-1.726-.198-2.407-.48-.735-.305-1.429-.834-1.429-1.66V5.467h.001L2.5 5.417c0-1.15 1.865-2.084 4.167-2.084 2.3 0 4.166.933 4.166 2.084v2.53a2.917 2.917 0 0 1 0 5.773c-.02.84-.69 1.371-1.431 1.673-.612.249-1.366.387-2.138.427.084.112.197.215.34.311.362.242.896.413 1.529.49 1.271.155 2.778-.087 3.736-.648.797-.467 1.157-1.357 1.285-2.88.084-1.002.063-2.193.04-3.6-.012-.666-.025-1.38-.027-2.146Zm-.755-4.014c-.493 0-.912.457-.912 1.02 0 .623.478 1.197 1.085 1.658a6.224 6.224 0 0 0 .993.61 6.685 6.685 0 0 0 1-.634c.614-.474 1.089-1.047 1.089-1.634 0-.563-.42-1.02-.912-1.02-.343 0-.616.191-.807.536l-.365.657-.364-.657c-.191-.345-.464-.536-.807-.536ZM6.667 7.5C8.03 7.5 9.24 7.173 10 6.667v1.28a2.917 2.917 0 0 0-.001 5.773c-.018.325-.28.644-.911.901-.636.259-1.513.389-2.417.378a8.85 8.85 0 0 1-.383-.012V7.492c.125.005.251.008.379.008ZM10 5.417c0 .09-.094.395-.76.727-.615.308-1.524.523-2.573.523-1.05 0-1.958-.215-2.574-.523-.665-.332-.76-.638-.76-.727 0-.09.095-.395.76-.728.616-.308 1.524-.522 2.574-.522 1.049 0 1.958.214 2.573.522.666.333.76.638.76.728Zm.417 7.5a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167Z"
      clipRule="evenodd"
    />
  </svg>
)
