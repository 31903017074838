import React from "react"
import { classNames } from "@ds/classNames.ts"

export interface ToolButtonProps {
  onClick?: () => void
  title: string
  icon: React.ElementType
  type?: "filled" | "tonal" | "outlined"
  dataTestId?: string
}

export const ToolButton = ({ onClick, title, icon: Icon, type = "filled", dataTestId }: ToolButtonProps) => {
  return (
    <div
      className={classNames(
        "flex h-6 w-6 cursor-pointer items-center justify-center rounded",
        type === "outlined" ? "bg-extra-light-grey" : "",
        type === "tonal" ? "bg-dark-plum" : "",
        type === "filled" ? "bg-yellow" : "",
      )}
      title={title}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {type === "outlined" && <Icon className="text-h5 text-grey" />}
      {(type === "tonal" || type === "filled") && <Icon className="text-h5 text-white" />}
    </div>
  )
}
