import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import fr from "./locale/fr.json"

i18n.use(initReactI18next).init({
  resources: {
    fr,
  },
  lng: "fr", // Set the initial language of the App
})
