import { GroupedList, RowsByGroupName } from "@ds/list/GroupedList"
import { InformationsDeRendezVous } from "../InformationsDeRendezVous"
import { useNavigate } from "@tanstack/react-router"
import { preconsultationRoute } from "@infra/navigation/router"
import { RendezVousParCabinetAPI } from "@data/rendezVous/queries/rendezVousParCabinetQuery"
import { trierParQuartDHeure } from "./trierParQuartDHeure"
import { RendezVousParCabinetQueryQuery } from "@data/gql/graphql"

type ListeDeRendezVousProps = {
  rendezVous: RendezVousParCabinetQueryQuery["rendezVousParCabinet"]
}

export const ListeDeRendezVousParCabinet = ({ rendezVous }: ListeDeRendezVousProps) => {
  const navigate = useNavigate()

  const result: { [key: string]: RendezVousParCabinetAPI[] } = trierParQuartDHeure(rendezVous)
  const donneesDeConsultation: RowsByGroupName = Object.entries(result)
    .sort()
    .map(([key, value]) => {
      const children = (
        <>
          {value.map((informations) => (
            <InformationsDeRendezVous
              key={informations.id}
              rendezVous={informations}
              onClick={() => navigate({ to: preconsultationRoute.to, params: { id: informations.id } })}
            />
          ))}
        </>
      )
      return {
        key,
        children,
      }
    })
  return <GroupedList groupedRows={donneesDeConsultation} />
}
