import { SVGProps } from "react"

export const PrimaryApp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.25 2.25826H9.75M13.5 3.63786V3.10099C13.5 2.65398 13.342 2.22527 13.0607 1.90919C12.7794 1.5931 12.3978 1.41553 12 1.41553H6C5.60218 1.41553 5.22064 1.5931 4.93934 1.90919C4.65804 2.22527 4.5 2.65398 4.5 3.10099V14.8992C4.5 15.3463 4.65804 15.775 4.93934 16.0911C5.22064 16.4071 5.60218 16.5847 6 16.5847H12C12.3978 16.5847 12.7794 16.4071 13.0607 16.0911C13.342 15.775 13.5 15.3463 13.5 14.8992V14.0649"
      stroke="#3A1C33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8875 12.2412L12.309 12.8241L13.3721 10.1534L13.3726 10.1538L14.9485 6.19317L13.5271 5.61023L12.7412 7.58452L9.91327 5.17578L8.9278 6.36785L11.1496 8.2603L11.15 8.26L12.1374 9.10125L10.8875 12.2412Z"
      fill="#3A1C33"
    />
    <path
      d="M11.154 10.1473L11.5088 9.26309L10.775 8.6368L7.98633 9.53546L8.45034 11.0186L11.154 10.1473Z"
      fill="#3A1C33"
    />
    <path d="M15.6346 10.0491L14.3994 8.99696L13.7958 10.5143L14.6492 11.2412L15.6346 10.0491Z" fill="#3A1C33" />
  </svg>
)
