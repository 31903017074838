import { useEffect } from "react"
import { toast, toastify } from "@infra/toast/toast.tsx"
import { Cross } from "@ds/icons/Cross.tsx"
import { PlainButton } from "@ds/button/PlainButton.tsx"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useTranslation } from "react-i18next"
import { Bell } from "@ds/icons/Bell.tsx"

export const useNotificationsMessagerie = () => {
  const { t } = useTranslation()
  const { messagerieMedecins } = useFlags()

  useEffect(() => {
    if (messagerieMedecins && "Notification" in window) {
      if (Notification.permission === "default") {
        toast.info(
          <div className={"flex flex-col items-center gap-2"}>
            <div>{t("messagerie.accepterLesNotificationsNavigateur")}</div>
            <PlainButton
              title={t("messagerie.accepterLesNotificationsNavigateurOK")}
              onClick={requestPermissions}
              type={"outlined"}
            />
          </div>,
          {
            autoClose: false,
            hideProgressBar: true,
            bodyClassName: () => "flex text-p-small text-black items-start",
            closeButton: ({ closeToast }) => (
              <div onClick={closeToast} data-testid={"close-toast-button"}>
                <Cross className="text-h4 text-black" />
              </div>
            ),
            icon: <Bell className="text-h2 text-dark-plum" />,
          },
        )
      }
    }
  }, [])

  const requestPermissions = () => {
    Notification.requestPermission().then(() => {
      toastify.dismiss()
    })
  }

  const envoyerUneNotificationDesktop = (titre: string, options: NotificationOptions) => {
    if (Notification.permission === "granted") {
      const notification = new Notification(titre, options)
      notification.onclick = () => {
        const url = window.location.href
        if (url.includes("messagerie")) {
          window.focus()
        } else {
          window.open(window.location.origin + "/messagerie")
        }
      }
      return notification
    }
  }

  return { envoyerUneNotificationDesktop }
}
