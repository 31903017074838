import { graphql } from "@data/gql"

export const rechercherVaccinsQuery = graphql(`
  query rechercherVaccinsQuery($recherche: String!) {
    vaccins(recherche: $recherche) {
      id
      nom
    }
  }
`)
