import { SVGProps } from "react"

export const Envelope = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <rect width={16} height={10.827} x={2} y={5.036} fill="currentColor" rx={1.208} stroke="none" />
      <path
        strokeLinecap="round"
        d="M2.536 6.62c.282.154.756.511 1.019.687.9.604 1.764 1.272 2.697 1.827.552.329 1.305.851 1.855 1.237.55.387 1.246.93 1.951 1.035.385.058.692-.221.968-.452.206-.172.412-.373.604-.516.09-.066.194-.127.276-.209.178-.177.433-.208.608-.394.337-.359 1.295-.976 1.71-1.242 0 0 1.182-.867 1.51-1.052.217-.122.368-.418.602-.509.308-.12.79-.486.971-.744"
      />
    </svg>
  )
}
