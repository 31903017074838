import { ReactElement } from "react"

type GroupedListProps = {
  groupedRows: RowsByGroupName
}

export type RowsByGroupName = {
  key: string
  children: ReactElement | ReactElement[]
}[]

export const GroupedList = ({ groupedRows }: GroupedListProps) => {
  return groupedRows.map(({ key, children }) => {
    return (
      <div className="mb-8 flex flex-col gap-2" key={key}>
        <div className="text-h3">{key}</div>
        {children}
      </div>
    )
  })
}
