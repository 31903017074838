import { useEffect, useState } from "react"
import { useChatContext } from "stream-chat-react"
import { filtreConversationsNonArchivees } from "../filtreConversations"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useTranslation } from "react-i18next"
import { useNotificationsMessagerie } from "./useNotificationsMessagerie"

export const useMessagerieUnreadCount = () => {
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadCountAideMedecin, setUnreadCountAideMedecin] = useState(0)
  const { client } = useChatContext()
  const { identifiantDuCabinet } = usePreferences()
  const { t } = useTranslation()

  const { envoyerUneNotificationDesktop } = useNotificationsMessagerie()

  const compterLesConversationsNonLues = async (identifiantDuCabinet: string, userId: string) => {
    const isUserMedecin = client.user?.metier === "medecin"
    const channels = await client.queryChannels(
      filtreConversationsNonArchivees(identifiantDuCabinet, userId, isUserMedecin),
    )
    const conversationsAvecDesNouveauxMessages = channels.filter((channel) => channel.state.unreadCount > 0)
    const nouvellesConversations = channels.filter((channel) =>
      client.user?.metier === "medecin"
        ? Object.values(channel.state.members).some((member) => member.user?.id === client.user?.id) &&
          channel.state.unreadCount > 0
        : !Object.values(channel.state.members).some((member) => member.user?.metier === "assistante_medicale"),
    )
    const conversationsUnread =
      client.user?.metier === "medecin"
        ? new Set(nouvellesConversations)
        : new Set(conversationsAvecDesNouveauxMessages.concat(nouvellesConversations))
    setUnreadCount(conversationsUnread.size)

    const nouvellesConversationsAideMedecin = channels.filter(
      (channel) =>
        Object.values(channel.state.members).some(
          (member) => member.user?.id === client.user?.id && client.user?.metier === "medecin",
        ) &&
        channel.data?.demandeAideMedecin &&
        channel.state.unreadCount > 0,
    )
    setUnreadCountAideMedecin(nouvellesConversationsAideMedecin.length)
  }

  useEffect(() => {
    const userId = client.user?.id
    if (identifiantDuCabinet && userId) {
      compterLesConversationsNonLues(identifiantDuCabinet, userId).then()
      const intervalId = setInterval(async () => {
        await compterLesConversationsNonLues(identifiantDuCabinet, userId)
      }, 10000)
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifiantDuCabinet, client.user?.id, client])

  useEffect(() => {
    if (client.user?.metier === "medecin") {
      const { unsubscribe } = client.on("message.new", (event) => {
        const channelDemandeAideMedecinParticipant =
          client.activeChannels[event.cid as string] !== undefined &&
          (client.activeChannels[event.cid as string].data?.demandeAideMedecin as boolean) === true &&
          client.activeChannels[event.cid as string].state?.members[client.userID as string] !== undefined

        if (channelDemandeAideMedecinParticipant) {
          const nomEmetteur = `${event.user?.prenom} ${event.user?.nom}`

          if (event.message?.type === "system" && (event.message?.demandeAideMedecin as boolean) === true) {
            envoyerUneNotificationDesktop(t("messagerie.consolePrimary"), {
              body: `${event.message.text}`,
            })
          }

          if (event.message?.type === "regular" && event.user?.role === "patient") {
            envoyerUneNotificationDesktop(t("messagerie.consolePrimary"), {
              body: `${nomEmetteur} \n${event.message.text}`,
            })
          }
        }
      })

      return unsubscribe
    }
  })
  return {
    unreadCount,
    unreadCountAideMedecin,
  }
}

export type UseMessagerieUnreadCount = typeof useMessagerieUnreadCount
