import { useEffect, useState } from "react"
import { Modal } from "@ds/modal/Modal.tsx"
import { PlainButton } from "@ds/button/PlainButton.tsx"
import { useTranslation } from "react-i18next"
import { useGQLMutation, useGraphQLQuery } from "@data/useGraphQL.ts"
import { recherchePatientsQuery } from "@data/patient/recherchePatientQuery.ts"
import { PatientTrouve } from "@features/patient/pages/PatientTrouve.tsx"
import { SearchBar } from "@features/patient/pages/SearchBar.tsx"
import { creerUneConversationAvecLePatient } from "@data/messagerie/creerUneConversationAvecLePatient.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { useChatContext } from "stream-chat-react"

export const useNouveauMessage = () => {
  const [modaleAffichee, setModaleAffichee] = useState(false)
  const { t } = useTranslation()
  const { client, setActiveChannel } = useChatContext()

  const ModaleNouveauMessage = () => {
    const [recherche, setRecherche] = useState("")
    const [patientSelectionne, setPatientSelectionne] = useState<string | null>()

    const { data } = useGraphQLQuery({
      document: recherchePatientsQuery,
      variables: { recherche },
      enabled: recherche.length > 2,
      keepDataWhileLoading: recherche.length > 2,
    })

    const { identifiantDuCabinet } = usePreferences()

    const { mutate: creerUneConversation } = useGQLMutation(creerUneConversationAvecLePatient, {
      onSuccess: async (data) => {
        if (data?.creerUneConversationAvecLePatient) {
          try {
            const channel = await client.getChannelById(
              "messaging",
              data.creerUneConversationAvecLePatient.identifiantStreamChat,
              {},
            )
            setActiveChannel(channel)
          } catch (error) {
            // TODO: handle error
            console.error("Impossible de rejoindre la conversation", error)
          }
        }
      },
    })

    const onClose = () => {
      setRecherche("")
      setPatientSelectionne(null)
      setModaleAffichee(false)
    }

    useEffect(() => {
      setPatientSelectionne(null)
    }, [data?.data?.patients, recherche])

    return (
      <Modal
        open={modaleAffichee}
        title={t("messagerie.creerUneConversation")}
        onClose={onClose}
        onConfirm={() => {
          creerUneConversation({
            identifiantProfil: patientSelectionne!,
            identifiantDuCabinet: identifiantDuCabinet!,
          })
          onClose()
        }}
        dataTestId="modale-nouveau-message"
        content={
          <div className="flex w-full flex-col">
            <SearchBar onType={setRecherche} />
            <>
              {data?.data?.patients && data?.data?.patients.length > 0 && (
                <>
                  <div className="pt-4" />
                  <div className="flex max-h-52 flex-col overflow-y-scroll border-t border-t-light-grey pt-4">
                    {data?.data?.patients?.map((patient) => (
                      <PatientTrouve
                        key={patient.id}
                        patient={patient}
                        selectPatient={setPatientSelectionne}
                        selected={patient.id === patientSelectionne}
                        disabled={patient.numeroDeTelephone === null}
                        disabledReason={t("messagerie.patientSansNumeroDeTelephone")}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          </div>
        }
        cancelButton={false}
        confirmButtonTitle={t("messagerie.creerLaConversation")}
        disabled={!patientSelectionne}
      />
    )
  }

  const BoutonNouveauMessage = () => {
    return (
      <PlainButton
        title={t("messagerie.nouveauMessage")}
        type="outlined"
        data-testid="nouveau-message"
        onClick={() => setModaleAffichee(true)}
      />
    )
  }

  return {
    BoutonNouveauMessage,
    ModaleNouveauMessage,
  }
}
