import { SVGProps } from "react"

export const Clock = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="8" fill="currentColor" stroke="none" />
    <path
      d="M10.6345 5.13708C10.5382 5.43717 10.4999 5.74028 10.4233 6.04698C10.3445 6.36195 10.3866 6.69655 10.2825 7.00902C10.1824 7.30927 10.181 7.65453 10.0817 7.96323C9.99357 8.23753 9.96607 8.5382 9.89402 8.81838C9.84631 9.00392 9.84461 9.23524 9.76627 9.40759C9.71786 9.5141 9.58691 10.241 9.58691 10.241"
      strokeLinecap="round"
    />
    <path
      d="M9.59277 10.2639C9.93937 10.6105 10.4405 10.9986 10.7523 11.3798C10.988 11.6679 11.2691 11.9039 11.5371 12.1619C11.7253 12.3432 11.9121 12.5513 12.1367 12.6886"
      strokeLinecap="round"
    />
  </svg>
)
