import { classNames } from "@ds/classNames"

type TagProps = {
  content: string | number
  disabled?: boolean
}

export const Tag = ({ content, disabled }: TagProps) => (
  <div
    className={classNames(
      "flex h-5 items-center rounded-xl px-2 py-2 text-h7",
      disabled ? "bg-extra-light-grey" : "bg-secondary-blue",
    )}
    data-testid="tag"
  >
    {content}
  </div>
)
