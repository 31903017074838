import { ExecutionResult } from "graphql"
import { useMutation, useQuery, useQueryClient, QueryKey, DefaultError, keepPreviousData } from "@tanstack/react-query"
import { TypedDocumentString } from "@data/gql/graphql.ts"
import { useAuth } from "@infra/auth"
import { ObjMap } from "graphql/jsutils/ObjMap"

export type UseGraphQLQueryProps<TResult, TVariables> = {
  document: TypedDocumentString<TResult, TVariables>
  variables?: TVariables
  refetchInterval?: number
  staleTime?: number
  keepDataWhileLoading?: boolean
  enabled?: boolean
}

export function useGraphQLQuery<TResult, TVariables>({
  document,
  variables,
  refetchInterval,
  staleTime,
  keepDataWhileLoading,
  enabled,
}: UseGraphQLQueryProps<TResult, TVariables>) {
  const { getIdTokenClaims, getAccessTokenSilently, logout } = useAuth()
  return useQuery({
    queryKey: [document, variables],
    queryFn: async () => {
      let idToken = await getIdTokenClaims()

      if (!idToken) {
        throw new Error("No idToken")
      }

      if (!idToken.exp) {
        throw new Error("idToken has no expiration date")
      }

      if (idToken.exp - 30 < Date.now() / 1000) {
        try {
          await getAccessTokenSilently({
            cacheMode: "off",
          })
          idToken = await getIdTokenClaims()
        } catch (error) {
          await logout()
          return Promise.reject({ message: "Could not refresh token", error })
        }
      }

      return fetch(import.meta.env.VITE_BACKEND_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken?.__raw}`,
        },
        body: JSON.stringify({
          query: document.toString(),
          variables: variables,
        }),
      }).then((response) => response.json()) as Promise<ExecutionResult<TResult>>
    },
    refetchInterval, //fréquence du refetch
    staleTime, //durée de validité des données
    placeholderData: keepDataWhileLoading ? keepPreviousData : undefined, //permet de ne pas vider le cache pendant le loading
    enabled, //ne lance pas la requête si enabled est false
  })
}

export interface UseGQLMutationOptions<TResult> {
  invalidateQueryKeys?: QueryKey[]
  onSuccess?: (data: TResult | null | undefined) => void
}

export function useGQLMutation<TResult, TVariables>(
  document: TypedDocumentString<TResult, TVariables>,
  options: UseGQLMutationOptions<TResult> = {},
) {
  const { getIdTokenClaims } = useAuth()
  const client = useQueryClient()
  return useMutation<ExecutionResult<TResult, ObjMap<unknown>>, DefaultError, TVariables>({
    onSuccess: (data) => {
      options.invalidateQueryKeys?.forEach((query) => {
        client.invalidateQueries({ queryKey: query, exact: false })
      })
      options.onSuccess?.(data.data)
    },
    mutationFn: async (variables: TVariables) => {
      const idToken = await getIdTokenClaims()
      const response = await fetch(import.meta.env.VITE_BACKEND_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken?.__raw}`,
        },
        body: JSON.stringify({
          query: document.toString(),
          variables: variables,
        }),
      })
      return response.json()
    },
  })
}
