import { PlainButton } from "@ds/button/PlainButton"
import { classNames } from "@ds/classNames"
import { Send } from "@ds/icons/Send"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useChannelStateContext, useChatContext, useMessageInputContext } from "stream-chat-react"
import { Participant } from "./Participant"
import { Plus } from "@ds/icons/Plus"
import { ReponsesRapides } from "./ReponsesRapides"
import { Textarea } from "@headlessui/react"
import { useParticipants } from "@features/messagerie/hooks/useParticipants.ts"

export interface CustomMessageInputProps {
  onClickReactiver: () => void
}

export const CustomMessageInput = ({ onClickReactiver }: CustomMessageInputProps) => {
  const { text, setText, handleChange, handleSubmit } = useMessageInputContext()
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()
  const [disabledSend, setDisabledSend] = useState(true)
  const { t } = useTranslation()
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const archive = !!channel.data?.archive_le

  const handleSubmitCustom = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (text && client.user) {
      handleSubmit(event, { user: { id: client.user.id } })
    }
  }

  const { participants, rejoindreConversation } = useParticipants()

  useEffect(() => {
    setDisabledSend(!text || text?.length < 1)
    if (inputRef.current?.scrollBy) {
      inputRef.current.focus()
      inputRef.current.scrollBy({ top: 200, behavior: "smooth" })
    }
  }, [text])

  if (archive) {
    return (
      <div className="mb-4 mt-2 flex h-16 items-center justify-center gap-4 rounded-xl bg-extra-light-grey py-4">
        <div data-testid="reactiver-conversation">
          <PlainButton title={t("messagerie.reactiverCourt")} onClick={onClickReactiver} type="outlined" />
        </div>
      </div>
    )
  }

  if (Object.values(channel.state?.members).filter((member) => member.user_id === client.user?.id).length === 0) {
    return (
      <>
        {!archive && (
          <div className="mb-4 mt-2 flex h-16 items-center justify-center gap-4 rounded-xl bg-extra-light-grey py-4">
            <div className="flex items-center gap-2 text-p-tiny">
              <div>{t("membreDuCabinet", { count: participants.length })}</div>
              <div className="flex -space-x-1">
                {participants
                  ?.reverse()
                  .map((participant) => (
                    <Participant
                      key={participant.id}
                      {...participant}
                      className="text-md h-7 w-7 border border-extra-light-grey"
                    />
                  ))}
              </div>
            </div>
            <div data-testid="rejoindre-conversation" onClick={rejoindreConversation}>
              <PlainButton title={t("rejoindre")} icon={<Plus />} type={"outlined"} />
            </div>
          </div>
        )}
      </>
    )
  }

  const BoutonEnvoyer = () => (
    <button type="button" onClick={handleSubmitCustom} disabled={disabledSend}>
      <Send className={classNames("text-h2", disabledSend ? "text-extra-light-grey" : "text-yellow")} />
    </button>
  )

  return (
    <div className="mb-4 mt-2 flex flex-col rounded-xl border-[1px] border-extra-light-grey p-1">
      <Textarea
        autoFocus
        value={text}
        className="m-2 resize-none border-none text-p-small outline-none"
        onChange={handleChange}
        onInput={(event) => {
          event.currentTarget.style.height = ""
          event.currentTarget.style.height = event.currentTarget.scrollHeight + "px"
        }}
        placeholder={t("contenuDuMessage")}
        ref={inputRef}
      />
      <div className="flex justify-between p-4">
        <ReponsesRapides text={text} setText={setText} />
        <BoutonEnvoyer />
      </div>
    </div>
  )
}
