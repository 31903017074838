import { Channel, DefaultGenerics, StreamChat } from "stream-chat"
import { Channel as ChannelType } from "stream-chat/dist/types/channel"

export const channelRenderFilterFn = (channelList: Channel[], filtreMessagerie: string, client: StreamChat) => {
  let channels: ChannelType[] = []
  const conversationNonArchivee = (channel: ChannelType) => channel.data?.archive_le === undefined

  if (filtreMessagerie === "tous") {
    channels = channelList.filter(conversationNonArchivee)
  }

  if (filtreMessagerie === "archive") {
    const conversationArchivee = (channel: ChannelType) => channel.data?.archive_le !== undefined
    channels = channelList.filter(conversationArchivee)
  }

  if (filtreMessagerie === "ma-boite") {
    const utilisateurDansLaConversation = (channel: ChannelType) => channel.state.members[client.user!.id] !== undefined

    // ma boite
    const filtreMaBoiteAssistanteMedicale = (conversation: ChannelType<DefaultGenerics>) => {
      const pasDAssistanteMedicaleDansLaConversation = !Object.values(conversation.state.members).some(
        (member) => member.user?.metier === "assistante_medicale",
      )

      return (
        (pasDAssistanteMedicaleDansLaConversation || utilisateurDansLaConversation(conversation)) &&
        conversationNonArchivee(conversation)
      )
    }

    const filtreMaBoiteMedecin = (conversation: ChannelType) =>
      utilisateurDansLaConversation(conversation) && conversationNonArchivee(conversation)

    const medecin = client.user?.metier === "medecin"

    channels = channelList.filter(medecin ? filtreMaBoiteMedecin : filtreMaBoiteAssistanteMedicale)
  }

  return channels
}
