import { SVGProps } from "react"

export const ChevronDown = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <div data-testid="chevron-down">
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props} viewBox="0 0 18 18">
      <path
        stroke="#101010"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13.822 6.536 9.5 10.857 5.18 6.536"
      />
    </svg>
  </div>
)
