import { graphql } from "@data/gql"

export const membreEquipeMedicaleConnecteQuery = graphql(`
  query membreEquipeMedicale {
    membreEquipeMedicaleConnecte {
      nom_libelle_long
      photo
    }
  }
`)
