import { ChannelFilters } from "stream-chat"

export const filtreConversations = (
  identifiantDuCabinet: string,
  clientUserId: string,
  isUserMedecin: boolean,
): ChannelFilters => {
  const baseFilter = { type: "messaging", last_message_at: { $gte: "2024-01-01T00:00:00.00Z" } }
  const memberFilter = { members: { $in: [clientUserId] } }
  const teamFilter = { team: identifiantDuCabinet }

  switch (isUserMedecin) {
    case true:
      return {
        $and: [baseFilter, memberFilter],
      }

    case false:
      return {
        $and: [
          baseFilter,
          teamFilter,
          {
            $or: [memberFilter, { team: identifiantDuCabinet }],
          },
        ],
      }
  }
}

export const filtreConversationsNonArchivees = (
  identifiantDuCabinet: string,
  clientUserId: string,
  isUserMedecin: boolean,
): ChannelFilters => ({
  $and: [
    {
      archive_le: { $exists: false },
    },
    filtreConversations(identifiantDuCabinet, clientUserId, isUserMedecin),
  ],
})
