import { SVGProps } from "react"
export const Thermometer = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.998 18.366a3.32 3.32 0 0 0 1.651-.44l.021.022.082-.083a3.331 3.331 0 0 0 .303-5.453c.014-.098.021-.197.02-.298L12.07 3.78a2.083 2.083 0 0 0-4.167.003l.007 8.333c0 .101.008.2.022.298a3.333 3.333 0 0 0 2.067 5.95ZM8.45 13.067l-.003.002c-.09.07-.174.148-.254.23l3.538 3.532c.083-.08.16-.166.232-.257L8.45 13.067Zm-.863 1.291c.034-.123.078-.242.13-.357l3.313 3.307a2.477 2.477 0 0 1-.357.131l-3.086-3.08Zm-.051 1.127a2.502 2.502 0 0 0 2.01 2.007l-2.01-2.007Zm1.208-3.301 3.629 3.622c.041-.127.073-.259.093-.394l-3.724-3.718v.423l.002.067Zm-.003-1.669 3.563 3.557a2.508 2.508 0 0 0-.764-1.006.833.833 0 0 1-.313-.556l-2.48-2.477h-.006v.482Zm1.184-.483 1.317 1.315v-.489l-.828-.826h-.489ZM8.74 9.2l2.5-.002v-.833h-.834a.417.417 0 1 1 0-.833h.833v-.834h-.834a.417.417 0 0 1 0-.833h.833l-.001-.834h-.834a.417.417 0 0 1 0-.832l.833-.001V3.78a1.25 1.25 0 1 0-2.5.002L8.74 9.2Z"
      clipRule="evenodd"
    />
  </svg>
)
