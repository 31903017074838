import { SVGProps } from "react"

export const Weight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.938 6.771c.31.341.215.865-.162 1.13l-2.03 1.423c-.377.264-.892.158-1.264-.113a2.514 2.514 0 0 0-1.44-.48 2.52 2.52 0 0 0-1.455.432c-.38.258-.899.348-1.267.07L5.34 7.744c-.368-.276-.445-.803-.125-1.134a6.667 6.667 0 0 1 9.724.162Zm-.69.481a5.831 5.831 0 0 0-8.36-.139L7.82 8.567a.146.146 0 0 0 .054.002.553.553 0 0 0 .246-.096 3.353 3.353 0 0 1 2-.574.419.419 0 0 1 .062-.095l1.144-1.316a.417.417 0 0 1 .629.547l-.898 1.032a3.356 3.356 0 0 1 .915.47.552.552 0 0 0 .242.104.147.147 0 0 0 .055 0l1.98-1.389ZM12.272 8.64l-.003.002.003-.002Zm-4.456-.074.004.002-.004-.002Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.09 17.5c.885 0 1.615-.691 1.664-1.574l.648-11.667A1.667 1.667 0 0 0 15.738 2.5H4.262c-.957 0-1.717.804-1.664 1.76l.648 11.666c.049.883.78 1.574 1.664 1.574h10.18Zm-10.18-.833h10.18a.833.833 0 0 0 .832-.788l.648-11.666a.833.833 0 0 0-.832-.88H4.262a.833.833 0 0 0-.832.88l.648 11.667c.025.441.39.787.832.787Z"
      clipRule="evenodd"
    />
  </svg>
)
