import { classNames } from "@ds/classNames"
import { ReactNode } from "react"

type SubmitButtonProps = {
  title?: string
  onClick?: () => unknown
  icon?: ReactNode
  type: "filled" | "tonal" | "outlined"
  disabled?: boolean
}

export const SubmitButton = ({ title, onClick, icon, type, disabled }: SubmitButtonProps) => {
  return (
    <button
      className={classNames("flex w-64 items-center justify-center gap-2 rounded-xl px-12 py-4 text-links-small", {
        "bg-yellow hover:bg-yellow-hover": type === "filled",
        "bg-dark-plum text-white hover:bg-dark-plum-hover": type === "tonal",
        "border border-dark-plum bg-white text-dark-plum hover:border-dark-plum-hover hover:text-dark-plum-hover":
          type === "outlined",
        "cursor-not-allowed bg-extra-light-grey hover:bg-extra-light-grey": disabled === true,
      })}
      onClick={onClick}
    >
      {title && <div>{title}</div>}
      {icon && <div>{icon}</div>}
    </button>
  )
}
