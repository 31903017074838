import { graphql } from "@data/gql"

export const cabinetsQuery = graphql(`
  query cabinetsQuery {
    cabinets {
      id
      nom
    }
  }
`)
