import { Modal } from "@ds/modal/Modal.tsx"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "@infra/toast/toast.tsx"
import { useChatContext } from "stream-chat-react"
import { buildNom } from "@features/messagerie/Participant.tsx"
import { Soignant } from "@features/messagerie/hooks/useListeSoignantsDuCabinet.ts"

export const useArchivage = () => {
  const [afficherConfirmation, setAfficherConfirmation] = useState(false)
  const { t } = useTranslation()
  const { channel, client } = useChatContext()
  const channelArchive = channel?.data?.archive_le !== null && channel?.data?.archive_le !== undefined

  const archiver = async () => {
    const archivePar = buildNom(client.user as Soignant) as string
    await channel?.updatePartial({ set: { archive_le: new Date().toISOString(), archive_par: archivePar } })
    await channel?.sendMessage(
      {
        //@ts-expect-error(force SDK to generate message id)
        id: null,
        type: "system",
        text: archivePar + " " + t("messagerie.aArchiveLaConversation"),
        user_id: client?.userID,
        silent: true,
        event: { type: "channel.updated" },
      },
      { skip_push: true },
    )
    toast.success(t("messagerie.conversationArchivee"))
  }

  const reactiver = async () => {
    const reactivePar = buildNom(client.user as Soignant) as string
    await channel?.updatePartial({ unset: ["archive_le", "archive_par"] })
    await channel?.sendMessage(
      {
        //@ts-expect-error(force SDK to generate message id)
        id: null,
        type: "system",
        text: reactivePar + " " + t("messagerie.aReactiveLaConversation"),
        user_id: client?.userID,
        silent: true,
        event: { type: "channel.updated" },
      },
      { skip_push: true },
    )
    toast.success(t("messagerie.conversationReactivee"))
  }

  return {
    setAfficherConfirmation,
    Modale: () => (
      <Modal
        open={afficherConfirmation}
        onClose={() => setAfficherConfirmation(false)}
        onConfirm={async () => {
          if (channelArchive) {
            await reactiver()
          } else {
            await archiver()
          }
          setAfficherConfirmation(false)
        }}
        title={
          channelArchive ? t("messagerie.titreConfirmationReactivation") : t("messagerie.titreConfirmationArchivage")
        }
        content={
          channelArchive
            ? t("messagerie.messageConfirmationReactivation")
            : t("messagerie.messageConfirmationArchivage")
        }
        dataTestId={channelArchive ? "modal-confirmation-reactivation" : "modal-confirmation-archivage"}
      />
    ),
  }
}
