import { SVGProps } from "react"

export const Copy = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 19" fill="none" {...props}>
      <path
        d="M3.87525 12.5C2.9775 12.5 2.25 11.7725 2.25 10.8748V4.37525C2.25 3.4775 2.9775 2.75 3.87525 2.75H10.3755C11.2725 2.75 12 3.4775 12 4.37525"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1248 16.25H7.6245C6.7275 16.25 6 15.5225 6 14.6248V8.1245C6 7.2275 6.7275 6.5 7.62525 6.5H14.1255C15.0225 6.5 15.75 7.2275 15.75 8.12525V14.6255C15.75 15.5225 15.0225 16.25 14.1248 16.25V16.25Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
