import { SVGProps } from "react"

export const ListViewCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="#28303F"
      fillRule="evenodd"
      d="M6.75 8.792a2.75 2.75 0 1 0-5.5 0 2.75 2.75 0 0 0 5.5 0Zm0 8a2.75 2.75 0 1 0-5.5 0 2.75 2.75 0 0 0 5.5 0Zm2.5-10a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 7.25a.75.75 0 0 0 0 1.5h6a.75.75 0 1 0 0-1.5h-6Zm-.75-3.25a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5H10a.75.75 0 0 1-.75-.75Zm.75 7.25a.75.75 0 0 0 0 1.5h12a.75.75 0 1 0 0-1.5H10Z"
      clipRule="evenodd"
    />
  </svg>
)
