import { Chat } from "stream-chat-react"
import { ReactNode } from "react"
import { useGraphQLQuery } from "@data/useGraphQL"
import { streamChatQuery } from "@data/messagerie/streamChatQuery"
import { useEffect, useState } from "react"
import { DefaultGenerics, ExtendableGenerics, StreamChat } from "stream-chat"
import { v4 as uuid } from "uuid"

const useCreateChatClient = <SCG extends ExtendableGenerics = DefaultGenerics>({
  apiKey,
  token,
  userId,
}: {
  apiKey: string
  token: string | null | undefined
  userId: string | null | undefined
}) => {
  const [chatClient, setChatClient] = useState<{ client: StreamChat<SCG>; key: string }>({
    client: new StreamChat<SCG>(apiKey),
    key: uuid(),
  })
  const [cachedUserId, setCachedUserId] = useState(userId)

  if (userId !== cachedUserId) {
    setCachedUserId(userId)
  }

  useEffect(() => {
    const client = new StreamChat<SCG>(apiKey)

    if (!!userId && !!token) {
      const connectionPromise = client.connectUser({ id: userId }, token).then(() => {
        setChatClient({ client, key: uuid() })
      })

      return () => {
        const newClient = new StreamChat<SCG>(apiKey)
        setChatClient({ client: newClient, key: uuid() })
        connectionPromise.then(() => {
          client.disconnectUser()
        })
      }
    }
  }, [userId, token, setChatClient, apiKey])

  return chatClient
}

export const StreamChatProvider = ({ children }: { children: ReactNode }) => {
  const apiKey = import.meta.env.VITE_STREAM_API_KEY as string
  const { data } = useGraphQLQuery({ document: streamChatQuery })
  const token = data?.data?.streamChat?.token
  const userId = data?.data?.streamChat?.userId

  const { client, key } = useCreateChatClient({
    apiKey,
    token,
    userId,
  })

  return (
    <Chat client={client} key={key}>
      {children}
    </Chat>
  )
}
