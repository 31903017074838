import { ComponentType, useEffect } from "react"
import { createRoute, createRouter as createTSRouter, useNavigate } from "@tanstack/react-router"
import { rootRoute } from "./rootRoute.tsx"
import { useAuth } from "@infra/auth"
import { usePreferences } from "../../infra/preferences/usePreferences.tsx"
import { format, isMatch } from "date-fns"
import { useTrackPageView } from "@infra/analytics/useTrackPageView.ts"
import { useLDClient } from "launchdarkly-react-client-sdk"

interface Pages {
  ChargementPage: ComponentType<object>
  ConsultationsPage: ComponentType<object>
  DetailPreconsultationPage: ComponentType<object>
  QuestionnaireDrawer: ComponentType<object>
  PatientPage: ComponentType<object>
  MessageriePage: ComponentType<object>
  register: (di: Partial<Pages>) => unknown
}

// @ts-expect-error("")
const registry: Pages = {}

export const register = (di: Partial<Pages>) => {
  Object.assign(registry, di)
}

const handler: ProxyHandler<Pages> = {
  get(target, property) {
    // @ts-expect-error("")
    if (target[property]) {
      // @ts-expect-error("")
      return target[property]
    }
    throw new Error(`Pages: ${String(property)} not registered`)
  },
}

const pages = new Proxy<Pages>(registry, handler)

export const defaultSearchDate = format(new Date(), "yyyy-MM-dd")
export const defaultSearchAppRoute = { date: defaultSearchDate }

export const appRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <pages.ConsultationsPage />,
  validateSearch: (search: Record<string, unknown>): RootSearch => ({
    date:
      typeof search.date == "string" && isMatch(search.date, "yyyy-MM-dd")
        ? (search.date as string)
        : defaultSearchDate,
    modal: typeof search.modal == "boolean" ? search.modal : undefined,
    tabIndex: typeof search.tabIndex == "number" ? search.tabIndex : undefined,
  }),
})

type RootSearch = {
  date: string
  modal?: boolean
  tabIndex?: number
}

export const chargementPageRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/chargement",
  component: () => <pages.ChargementPage />,
})

export const preconsultationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/preconsultation/$id",
  component: () => <pages.DetailPreconsultationPage />,
})

export const preconsultationQuestionnaireDrawerRoute = createRoute({
  getParentRoute: () => preconsultationRoute,
  path: "/questionnaire",
  component: () => <pages.QuestionnaireDrawer />,
})

export const patientRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/patients/$id",
  component: () => <pages.PatientPage />,
})

export const messagerieRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/messagerie",
  component: () => <pages.MessageriePage />,
})

const routeTree = rootRoute.addChildren([
  appRoute,
  chargementPageRoute,
  preconsultationRoute,
  preconsultationQuestionnaireDrawerRoute,
  patientRoute,
  messagerieRoute,
])

export const createRouter = () =>
  createTSRouter({
    routeTree,
    InnerWrap: ({ children }) => {
      const { isAuthenticated, getIdTokenClaims } = useAuth()
      const { identifiantDuCabinet } = usePreferences()
      const client = useLDClient()

      const navigate = useNavigate()
      useTrackPageView()
      useEffect(() => {
        if (client?.getContext().anonymous) {
          getIdTokenClaims().then((claims) => {
            if (claims) {
              client.identify({
                kind: "user",
                key: claims?.sub,
                name: claims?.name,
                email: claims?.email,
              })
            }
          })
        }
        if (isAuthenticated && !identifiantDuCabinet) {
          navigate({ to: chargementPageRoute.to })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isAuthenticated, identifiantDuCabinet, navigate])
      return children
    },
    context: { user: undefined!, isAuthenticated: false, loginWithRedirect: undefined! },
  })
