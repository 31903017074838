import { useEffect, useState } from "react"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { useChatContext } from "stream-chat-react"

export type Soignant = {
  id: string
  civilite?: string
  prenom?: string
  nom?: string
  urlAvatar?: string
}

export const useListeSoignantsDuCabinet = () => {
  const { identifiantDuCabinet } = usePreferences()
  const { client } = useChatContext()
  const [listeSoignantsDuCabinet, setListeSoignantsDuCabinet] = useState<Soignant[]>()

  const genererLaListeDeSoignantsDuCabinet = async () => {
    return identifiantDuCabinet
      ? await client?.queryUsers({ role: "soignant", teams: { $contains: identifiantDuCabinet } }).then((response) =>
          response.users.map((u) => {
            return {
              id: u.id,
              civilite: u.civilite,
              prenom: u.prenom,
              nom: u.nom,
              urlAvatar: u.urlAvatar as string,
            } as Soignant
          }),
        )
      : []
  }

  useEffect(() => {
    genererLaListeDeSoignantsDuCabinet().then((soignants) => {
      setListeSoignantsDuCabinet(soignants)
    })
  }, [identifiantDuCabinet])

  return {
    listeSoignantsDuCabinet,
  }
}
