import { graphql } from "@data/gql"

export const reponsesRapidesQuery = graphql(`
  query reponsesRapidesQuery($identifiantCabinet: String!) {
    reponsesRapides(identifiantCabinet: $identifiantCabinet) {
      id
      titre
      contenu
    }
  }
`)
