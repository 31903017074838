import { useAuth0 } from "@auth0/auth0-react"
export const useAuth = () => {
  const auth0 = useAuth0()
  return {
    isAuthenticated: auth0.isAuthenticated,
    loginWithRedirect: auth0.loginWithRedirect,
    getIdTokenClaims: auth0.getIdTokenClaims,
    logout: auth0.logout,
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    id: auth0.user?.sub,
  }
}
export type UseAuth = typeof useAuth
