import { SVGProps } from "react"

export const ArrowsLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4Zm10.45 5.4a.75.75 0 0 1 .15 1.05l-2.1 2.8a1.25 1.25 0 0 0 0 1.5l2.1 2.8a.75.75 0 0 1-1.2.9l-2.1-2.8a2.75 2.75 0 0 1 0-3.3l2.1-2.8a.75.75 0 0 1 1.05-.15ZM11.6 8.45a.75.75 0 0 0-1.2-.9l-2.1 2.8a2.75 2.75 0 0 0 0 3.3l2.1 2.8a.75.75 0 1 0 1.2-.9l-2.1-2.8a1.25 1.25 0 0 1 0-1.5l2.1-2.8Z"
      clipRule="evenodd"
    />
  </svg>
)
