import { classNames } from "@ds/classNames"

interface AvatarProps {
  name: string
  style?: "large" | "small" | "x-small"
  color?: string
  className?: string
}

export const Avatar = ({ name, style = "large", color = "bg-secondary-green", className }: AvatarProps) => {
  const nameParts = name.split(" ")
  let initials = ""
  const firstInitial = nameParts[0].charAt(0).toUpperCase()
  if (nameParts.length == 1) {
    initials = firstInitial
  } else {
    const secondInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    initials = firstInitial + secondInitial
  }
  return (
    <div
      data-testid={"avatar-" + initials}
      className={classNames(
        "pointer-events-none flex items-center justify-center",
        color,
        {
          "h-9 w-9 rounded-xl p-2 text-h5": style == "large",
          "h-5 w-5 rounded p-1 text-h7": style == "small",
          "h-5 w-5 rounded-full p-1 text-h7": style == "x-small",
        },
        className,
      )}
    >
      <span translate="no">{initials}</span>
    </div>
  )
}
