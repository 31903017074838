import { classNames } from "@ds/classNames"
import { useTranslation } from "react-i18next"
import { Channel } from "stream-chat"
import { Participant, ParticipantProps } from "./Participant"
import { BaseSyntheticEvent } from "react"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { formatDistanceStrict } from "date-fns"
import { fr } from "date-fns/locale"
import { abregerUnites } from "./abregerUnites.ts"
import { useChatContext } from "stream-chat-react"
import { Check } from "@ds/icons/Check.tsx"
import { useFlags } from "launchdarkly-react-client-sdk"
import { Flag } from "@ds/icons/Flag.tsx"

type ConversationCardProps = {
  active?: boolean
  latestMessage?: string | JSX.Element
  channel: Channel
  setActiveChannel?:
    | ((
        newChannel?: Channel | undefined,
        watchers?: {
          limit?: number
          offset?: number
        },
        event?: BaseSyntheticEvent,
      ) => void)
    | undefined
  unread?: number
  displaysNew?: boolean
}

export const ConversationCard = (props: ConversationCardProps) => {
  const { t } = useTranslation()
  const { filtreMessagerie } = usePreferences()
  const { client } = useChatContext()
  const { messagerieMedecins } = useFlags()
  const channelData = props.channel.data
  const nom = (channelData?.created_by as { nom?: string })?.nom
  const prenom = (channelData?.created_by as { prenom?: string })?.prenom
  const titre = channelData?.objet as string
  const channelAvecDemandeAideMedecin = channelData?.demandeAideMedecin === true

  const aAuMoinsUnMessageDeSoignant =
    props.channel.state?.messageSets &&
    Object.values(props.channel.state?.messageSets).some((messageSet) => {
      return messageSet.messages.some((message) => message.user?.role === "soignant" && message.type === "regular")
    })

  const archives = filtreMessagerie === "archive"

  const unreadCount = client.user?.metier === "medecin" && !channelAvecDemandeAideMedecin ? 0 : props?.unread

  const participantsPrimary =
    props?.channel?.state?.members &&
    Object.values(props?.channel?.state?.members)
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .map((user) => user as unknown as ParticipantProps)

  let dernierMessageAbrege = ""
  if (props?.channel?.state?.last_message_at) {
    const dernierMessageDepuis = formatDistanceStrict(props?.channel?.state?.last_message_at as Date, new Date(), {
      locale: fr,
    })
    dernierMessageAbrege = abregerUnites(dernierMessageDepuis)
  }

  const userEstMembre =
    (client?.userID &&
      Object.values(props.channel?.state?.members ?? []).some((member) => member.user_id === client.userID)) ||
    false

  return (
    <div
      className={classNames(
        "group mt-1 flex flex-col gap-2 rounded-md p-3 hover:cursor-pointer hover:bg-extra-light-grey",
        props.active && "bg-extra-light-grey",
        archives && "opacity-50",
      )}
      data-testid="conversation-card"
      onClick={() => props.setActiveChannel?.(props.channel)}
    >
      <div className="flex items-center justify-between text-h7">
        <div className="itemps-center flex gap-1">
          {messagerieMedecins && channelAvecDemandeAideMedecin && (
            <Flag className="rounded bg-yellow p-0.5 text-p-tiny text-white" data-testid="demande-aide-medecin-icone" />
          )}
          <div data-testid="patient-name" className="truncate">
            {prenom?.toUpperCase() + " " + nom?.toUpperCase()}
          </div>
        </div>
        <div className="relative flex flex-row gap-1" key={props.channel?.id}>
          <div className="flex -space-x-1">
            {participantsPrimary &&
              participantsPrimary.length >= 1 &&
              participantsPrimary
                ?.reverse()
                .map((participant) => (
                  <Participant
                    key={participant.id}
                    {...participant}
                    className={classNames(
                      "border text-h7",
                      props.active ? "border-extra-light-grey" : "border-white group-hover:border-extra-light-grey",
                    )}
                  />
                ))}
          </div>
          {!aAuMoinsUnMessageDeSoignant && !!props.displaysNew && (
            <div className="rounded bg-dark-plum px-1 py-0.5 text-h7 text-white">{t("new").toUpperCase()}</div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="max-w-44 truncate text-h5" data-testid="channel-name">
          {titre}
        </div>
        <div className="pointer-events-none flex items-center justify-between">
          <div
            className={classNames("max-w-40 truncate", !userEstMembre || unreadCount === 0 ? "text-p-tiny" : "text-h6")}
            data-testid="last-message-content"
          >
            {typeof props.latestMessage === "string" ? "" : props.latestMessage}
          </div>

          <div className="flex flex-row items-center gap-1">
            {userEstMembre && <CocheLu channel={props.channel} className="text-p-tiny" />}
            <div
              className={classNames(
                "flex text-p-tiny",
                !userEstMembre || unreadCount === 0 ? "text-grey" : "text-valid",
              )}
            >
              {dernierMessageAbrege}
            </div>
            {userEstMembre && !!unreadCount && unreadCount > 0 && (
              <div
                className="flex h-4 w-4 items-center justify-center rounded-full bg-valid p-1 text-[8px] text-white"
                data-testid="unread-count"
              >
                {unreadCount}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CocheLu = ({ channel, className }: { channel: Channel; className?: string }) => {
  const message = channel.state.messages[channel.state.messages.length - 1]

  if (message?.user?.role !== "soignant") return

  const userPatient = Object.values(channel.state.members).find((member) => member.user?.role === "patient")
  if (!userPatient?.user_id) return

  const readState = channel.state.read[userPatient.user_id]
  const lastReadMessageDate = readState?.last_read

  if (!message.created_at) {
    return
  }

  if (message.created_at.getTime() > lastReadMessageDate?.getTime()) return

  return <Check className={classNames("stroke-blue", className)} />
}
