import { SVGProps } from "react"

export const Archive = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 6v5.611a4.513 4.513 0 0 0-1-.111h-1a3.5 3.5 0 0 0-2.8 1.4L14 14.5a2.5 2.5 0 0 1-4 0l-1.2-1.6A3.5 3.5 0 0 0 6 11.5H5c-.344 0-.678.039-1 .111V6a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4Zm2 10v3a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-3a3 3 0 0 1 3-3h1a2 2 0 0 1 1.6.8l1.2 1.6a4 4 0 0 0 6.4 0l1.2-1.6A2 2 0 0 1 18 13h1a3 3 0 0 1 3 3Z"
      clipRule="evenodd"
    />
  </svg>
)
