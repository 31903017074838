import { graphql } from "@data/gql"

export const creerUneConversationAvecLePatient = graphql(`
  mutation creerUneConversationAvecLePatientMutation($identifiantProfil: String!, $identifiantDuCabinet: String!) {
    creerUneConversationAvecLePatient(
      identifiantProfil: $identifiantProfil
      identifiantDuCabinet: $identifiantDuCabinet
    ) {
      identifiantStreamChat
    }
  }
`)
