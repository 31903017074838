import { graphql } from "@data/gql"

export const aideMettreEnForme = graphql(`
  query aideMettreEnForme($contenu: String!) {
    aideALaPreanamnese(contenu: $contenu) {
      miseEnForme {
        contenu
      }
    }
  }
`)
