import { format, roundToNearestMinutes, isBefore, isEqual } from "date-fns"

type RdvPartiel = {
  dateHeureDeDebut: Date
  patient: {
    nom: string
  }
}

export const trierParQuartDHeure = <T extends RdvPartiel>(input: T[]) => {
  const result: { [key: string]: T[] } = {}
  input.forEach((rdv) => {
    const { dateHeureDeDebut } = rdv

    const trancheHoraire = roundToNearestMinutes(dateHeureDeDebut, { roundingMethod: "floor", nearestTo: 15 })
    const heure = format(trancheHoraire, "HH:mm")

    result[heure] = result[heure] || []
    result[heure].push(rdv)

    result[heure].sort((rdv1, rdv2) => {
      if (isBefore(rdv1.dateHeureDeDebut, rdv2.dateHeureDeDebut)) {
        return -1
      }
      if (isEqual(rdv2.dateHeureDeDebut, rdv1.dateHeureDeDebut)) {
        return rdv1.patient.nom.localeCompare(rdv2.patient.nom)
      }
      return 0
    })
  })
  return result
}
