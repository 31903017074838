import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react"
import { Cross } from "@ds/icons/Cross.tsx"
import { useTranslation } from "react-i18next"
import { SubmitButton } from "@ds/button/SubmitButton.tsx"
import { ReactNode } from "react"

export interface ModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  dataTestId?: string
  content: string | ReactNode
  cancelButton?: boolean
  confirmButtonTitle?: string
  disabled?: boolean
}

export const Modal = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  dataTestId,
  cancelButton,
  confirmButtonTitle,
  disabled,
}: ModalProps) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50" data-testid={dataTestId}>
      <div className="fixed inset-0 grid w-screen grid-cols-6 grid-rows-5 bg-secondary-black p-6">
        <DialogPanel className="col-span-2 col-start-3 row-start-2 flex h-full flex-col rounded-xl bg-white">
          <DialogTitle className="flex w-full justify-center gap-1 rounded-t-xl bg-white p-6 text-h4">
            <div className="w-full flex-1 text-center">{title}</div>
            <button data-testid="modal-close" onClick={onClose}>
              <Cross className="text-p-medium text-black" />
            </button>
          </DialogTitle>
          <div className="flex flex-grow items-center bg-white px-6 text-center align-middle text-p-tiny">
            {content}
          </div>
          <div className="flex justify-center gap-4 rounded-b-xl bg-white p-6">
            {(cancelButton === undefined || cancelButton) && (
              <SubmitButton title={t("annuler")} onClick={onClose} type="outlined" />
            )}
            <SubmitButton
              title={confirmButtonTitle ? confirmButtonTitle : t("confirmer")}
              onClick={onConfirm}
              type="tonal"
              disabled={disabled}
            />
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
