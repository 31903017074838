import { graphql } from "@data/gql"

export const recherchePatientsQuery = graphql(`
  query patientsQuery($recherche: String!) {
    patients(recherche: $recherche) {
      id
      prenom
      nom
      dateDeNaissance
      sexe
      numeroDeTelephone
      compte {
        email
        identifiantUtilisateur
        numeroDeTelephone
      }
    }
  }
`)
