import { di } from "@di"
import { useRouterState } from "@tanstack/react-router"
import { useEffect } from "react"

export const useTrackPageView = () => {
  const { matches } = useRouterState()
  const { routeId: pathname } = matches.length > 0 ? matches[matches.length - 1] : { routeId: undefined }
  const analytics = di.analytics
  useEffect(() => {
    if (!pathname) {
      return
    }
    analytics.trackEvent("Page View", { pathname })
  }, [analytics, pathname])
}
