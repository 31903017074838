import { useChatContext } from "stream-chat-react"
import { buildNom } from "@features/messagerie/Participant.tsx"
import { Soignant } from "@features/messagerie/hooks/useListeSoignantsDuCabinet.ts"
import { toast } from "@infra/toast/toast.tsx"
import { useTranslation } from "react-i18next"

export const useDemandeAideMedecin = () => {
  const { t } = useTranslation()
  const { channel, client } = useChatContext()

  const demanderAideMedecin = async () => {
    const demandeRealiseePar = buildNom(client.user as Soignant) as string
    await channel?.updatePartial({ set: { demandeAideMedecin: true, demandeAideMedecinPar: demandeRealiseePar } })
    await channel?.sendMessage(
      {
        //@ts-expect-error(force SDK to generate message id)
        id: null,
        type: "system",
        text: demandeRealiseePar + " " + t("messagerie.demandeRealiseeMessage"),
        user_id: client?.userID,
        silent: true,
        event: { type: "channel.updated" },
        demandeAideMedecin: true,
      },
      { skip_push: true },
    )
    toast.success(t("messagerie.demandeRealiseeToast"))
  }

  const retirerDemandeAideMedecin = async () => {
    const demandeRetireePar = buildNom(client.user as Soignant) as string
    await channel?.updatePartial({ unset: ["demandeAideMedecin", "demandeAideMedecinPar"] })
    await channel?.sendMessage(
      {
        //@ts-expect-error(force SDK to generate message id)
        id: null,
        type: "system",
        text: demandeRetireePar + " " + t("messagerie.demandeRetireeMessage"),
        user_id: client?.userID,
        silent: true,
        event: { type: "channel.updated" },
      },
      { skip_push: true },
    )
  }
  return { demanderAideMedecin, retirerDemandeAideMedecin }
}
