export const abregerUnites = (dernierMessageDepuis: string) => {
  if (dernierMessageDepuis.includes("seconde")) {
    return dernierMessageDepuis.replace(/ secondes?/g, "s")
  }
  if (dernierMessageDepuis.includes("minute")) {
    return dernierMessageDepuis.replace(/ minutes?/g, "min")
  }
  if (dernierMessageDepuis.includes("heure")) {
    return dernierMessageDepuis.replace(/ heures?/g, "h")
  }
  if (dernierMessageDepuis.includes("jour")) {
    return dernierMessageDepuis.replace(/ jours?/g, "j")
  }
  if (dernierMessageDepuis.includes("mois")) {
    return dernierMessageDepuis.replace(/ mois?/g, "m")
  }
  if (dernierMessageDepuis.includes("année")) {
    return dernierMessageDepuis.replace(/ années?/g, "ans")
  }
  return dernierMessageDepuis
}
