import { Analytics } from "@infra/analytics/Analytics"
import { UseAuth } from "@infra/auth/useAuth"
import { UpdateService } from "@infra/updates/UpdateService"
import { ComponentType } from "react"

interface DI {
  UnreadCount: ComponentType
  SelecteurDeCabinet: ComponentType
  RecherchePatientModal: ComponentType
  analytics: Analytics
  useAuth: UseAuth
  UserProfile: ComponentType
  register: (di: Partial<DI>) => unknown
  updateServiceWorker: () => void
  updateService: UpdateService
}

// @ts-expect-error("")
const registry: DI = {}

export const register = (di: Partial<DI>) => {
  Object.assign(registry, di)
}

const handler: ProxyHandler<DI> = {
  get(target, property) {
    // @ts-expect-error("")
    if (target[property]) {
      // @ts-expect-error("")
      return target[property]
    }
    throw new Error(`DI: ${String(property)} not registered`)
  },
}

export const di = new Proxy<DI>(registry, handler)
