import { ComponentType } from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"

interface ProtectedProps {
  component: ComponentType
}

export const Protected: ComponentType<ProtectedProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component)
  return <Component />
}
