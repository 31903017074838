import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { ListViewCircle } from "@ds/icons/ListViewCircle.tsx"
import { useGraphQLQuery } from "@data/useGraphQL.ts"
import { reponsesRapidesQuery } from "@data/messagerie/reponsesRapidesQuery.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"

export interface ReponseRapideProps {
  text: string
  setText: (text: string) => void
}

export const ReponsesRapides = ({ text, setText }: ReponseRapideProps) => {
  const { identifiantDuCabinet } = usePreferences()
  const { data: reponsesRapides } = useGraphQLQuery({
    document: reponsesRapidesQuery,
    variables: { identifiantCabinet: identifiantDuCabinet! },
    enabled: identifiantDuCabinet !== undefined,
  })

  return (
    <>
      {reponsesRapides?.data?.reponsesRapides?.length !== undefined &&
        reponsesRapides?.data?.reponsesRapides?.length > 0 && (
          <Popover className="relative" data-testid="reponses-rapides">
            <PopoverButton className="outline-0">
              <ListViewCircle className="text-h3" />
            </PopoverButton>
            <PopoverPanel
              anchor="bottom start"
              className="absolute top-1 max-h-64 gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow"
            >
              {reponsesRapides?.data?.reponsesRapides?.map((reponseRapide, index) => (
                <div
                  key={"reponse-rapide-" + index}
                  className="not-active:bg-inherit flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny hover:bg-extra-light-grey active:bg-extra-light-grey"
                  onClick={() => setText(text + reponseRapide.contenu)}
                >
                  <div className="selected:text-dark-plum truncate">{reponseRapide.titre}</div>
                </div>
              ))}
            </PopoverPanel>
          </Popover>
        )}
      {reponsesRapides?.data?.reponsesRapides?.length !== undefined &&
        reponsesRapides?.data?.reponsesRapides?.length === 0 && <div></div>}
    </>
  )
}
