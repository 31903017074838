import { classNames } from "@ds/classNames"
import { ButtonHTMLAttributes, ReactNode } from "react"

type IconButtonProps = {
  children: ReactNode
}

export const IconButton = ({
  children,
  className,
  ...rest
}: IconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={classNames(
        "focus:light-grey flex h-7 w-7 items-center justify-center rounded bg-extra-light-grey text-lg hover:opacity-60 active:opacity-100",
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
