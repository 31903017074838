import { buildNom, ParticipantProps } from "@features/messagerie/Participant.tsx"
import { useChannelStateContext, useChatContext } from "stream-chat-react"
import { Soignant } from "@features/messagerie/hooks/useListeSoignantsDuCabinet.ts"
import { useTranslation } from "react-i18next"

export const useParticipants = () => {
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()
  const { t } = useTranslation()

  let participants =
    channel.state?.members &&
    Object.values(channel.state?.members)
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .map((user) => user as unknown as ParticipantProps)
      .reduce((categories, member) => [...categories, { ...member }], [] as Soignant[])

  const updateParticipants = (soignantsSelectionnes: Soignant[]) => {
    participants =
      channel.state?.members &&
      Object.values(channel.state?.members)
        .map((member) => member.user)
        .filter((user) => user?.role === "soignant")
        .map((user) => user as unknown as ParticipantProps)
    const ids = participants.map((soignant) => soignant.id)
    const newIds = soignantsSelectionnes.map((soignant) => soignant.id)
    const soignantsAjoutes = soignantsSelectionnes.filter((soignant) => !ids.includes(soignant.id))
    const soignantsRetires = participants.filter((soignant) => !newIds.includes(soignant.id))
    if (soignantsAjoutes.length > 0) {
      soignantsAjoutes.forEach((soignant) => {
        channel.addMembers(
          [soignant.id],
          {
            //@ts-expect-error(force SDK to generate message id)
            id: null,
            type: "system",
            text: (buildNom(soignant) as string) + " " + t("messagerie.aEteAjouteALaConversation"),
            user_id: soignant.id,
            silent: true,
            event: { type: "member.added" },
          },
          {
            skip_push: true,
          },
        )
      })
      // setParticipants((p) => [...p, ...soignantsAjoutes])
    }
    if (soignantsRetires.length > 0) {
      soignantsRetires.forEach((soignant) => {
        channel.removeMembers(
          [soignant.id],
          {
            //@ts-expect-error(force SDK to generate message id)
            id: null,
            type: "system",
            text: (buildNom(soignant) as string) + " " + t("messagerie.aEteRetireDeLaConversation"),
            user_id: soignant.id,
            silent: true,
            event: { type: "member.removed" },
          },
          {
            skip_push: true,
          },
        )
      })
      // setParticipants((p) => p.filter((soignant) => !soignantsRetires.includes(soignant)))
    }
  }

  const rejoindreConversation = () => {
    if (client.user) {
      channel.addMembers(
        [client.user?.id],
        {
          //@ts-expect-error(force SDK to generate message id)
          id: null,
          type: "system",
          text: (client?.user?.prenom as string) + " " + t("messagerie.aRejointLaConversation"),
          user: client.user,
          silent: true,
          event: { type: "member.added" },
        },
        {
          skip_push: true,
        },
      )
    }
  }

  return {
    participants,
    updateParticipants,
    rejoindreConversation,
  }
}
