import { Avatar } from "@ds/avatar/Avatar"
import { ToolButton } from "@ds/button/ToolButton"
import { Archive } from "@ds/icons/Archive"
import { Collaboration } from "@ds/icons/Collaboration"
import { buildNom, Participant, ParticipantProps } from "./Participant"
import { useTranslation } from "react-i18next"
import { useChannelStateContext, useChatContext } from "stream-chat-react"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react"
import { Doctolib } from "@ds/icons/Doctolib.tsx"
import { Soignant } from "@features/messagerie/hooks/useListeSoignantsDuCabinet.ts"
import { NoPrimaryApp } from "@ds/icons/NoPrimaryApp.tsx"
import { PatientDuChannel } from "@features/messagerie/hooks/usePatientDuChannel.ts"
import { useDemandeAideMedecin } from "@features/messagerie/hooks/useDemandeAideMedecin.ts"
import { useFlags } from "launchdarkly-react-client-sdk"
import { Flag } from "@ds/icons/Flag"
import { CabinetTag } from "./components/CabinetTag"

interface HeaderWithToolsProps {
  patient: PatientDuChannel | null | undefined
  profilPrincipal: boolean | null
  nonOnboarde: boolean | null
  onClickArchiver: () => void
  listeSoignantsDuCabinet: Soignant[] | null | undefined
  updateParticipants: (value: Soignant[]) => void
}

export const HeaderWithTools = ({
  patient,
  profilPrincipal,
  nonOnboarde,
  onClickArchiver,
  listeSoignantsDuCabinet,
  updateParticipants,
}: HeaderWithToolsProps) => {
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()
  const { t } = useTranslation()
  const { messagerieMedecins } = useFlags()
  const { demanderAideMedecin, retirerDemandeAideMedecin } = useDemandeAideMedecin()

  const nomDuPatient = Object.values(channel.state?.members)
    .filter((member) => member.user?.role === "patient")
    .map((member) => (member.user?.prenom + " " + member.user?.nom) as string)
    .find((name) => name)

  const participantsPrimary: ParticipantProps[] =
    channel.state?.members &&
    Object.values(channel.state?.members)
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .map((user) => user as unknown as ParticipantProps)

  const channelArchive = channel.data?.archive_le !== null && channel.data?.archive_le !== undefined
  const channelAvecDemandeAideMedecin = channel.data?.demandeAideMedecin === true
  const titre = channel.data?.objet as string

  const isUserMedecin = client.user?.metier === "medecin"

  return (
    <div className="mb-4 flex h-10 justify-between bg-white">
      <div className="flex flex-row items-center gap-2">
        <Avatar name={nomDuPatient || ""} color="bg-secondary-blue" />
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2">
            <div className="text-h7">{nomDuPatient?.toUpperCase()}</div>
            {nonOnboarde && <NoPrimaryApp data-testid="no-app" />}
            <a
              data-testid="lien-doctolib-patient"
              target="_blank"
              href={patient?.lienDoctolib}
              title={
                t("messagerie.lienDoctolib") +
                " " +
                (patient?.prenom?.toUpperCase() ?? "") +
                " " +
                (patient?.nom?.toUpperCase() ?? "")
              }
              className="flex items-center"
            >
              {profilPrincipal === false && (
                <span className="mr-2 rounded bg-secondary-blue px-1 py-0.5 text-h7">
                  {(patient?.prenom?.toUpperCase() ?? "") + " " + (patient?.nom?.toUpperCase() ?? "")}
                </span>
              )}
              {patient?.lienDoctolib && patient?.lienDoctolib.trim() !== "" && (
                <Doctolib className="h-[0.813em] w-[0.813em] text-h3" />
              )}
            </a>
          </div>
          <div className="text-h4">{titre}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {isUserMedecin && <CabinetTag cabinetId={channel.data?.team} />}

        <div className="flex -space-x-1">
          {participantsPrimary
            ?.reverse()
            .map((participant) => (
              <Participant key={participant.id} {...participant} className="text-md h-7 w-7 border border-white" />
            ))}
        </div>

        {messagerieMedecins && (
          <ToolButton
            onClick={async () => {
              if (channelAvecDemandeAideMedecin) {
                await retirerDemandeAideMedecin()
              } else {
                await demanderAideMedecin()
              }
            }}
            title={
              channelAvecDemandeAideMedecin
                ? t("messagerie.retirerDemandeAideMedecin")
                : t("messagerie.demanderAideMedecin")
            }
            icon={Flag}
            type={channelAvecDemandeAideMedecin ? "filled" : "outlined"}
            dataTestId="bouton-demande-aide-medecin"
          />
        )}
        <DropDownCheckedList
          titre={t("participants")}
          liste={listeSoignantsDuCabinet ?? []}
          selection={participantsPrimary}
          setSelection={updateParticipants}
        />
        <ToolButton
          onClick={onClickArchiver}
          title={channelArchive ? t("messagerie.reactiver") : t("messagerie.archiver")}
          icon={Archive}
          type={channelArchive ? "tonal" : "outlined"}
          dataTestId="bouton-archiver"
        />
      </div>
    </div>
  )
}

type DropDownCheckedListProps = {
  titre?: string
  liste: Soignant[]
  selection: Soignant[]
  setSelection: (value: Soignant[]) => void
}

const DropDownCheckedList = ({ titre, liste, selection, setSelection }: DropDownCheckedListProps) => {
  const { t } = useTranslation()

  const byFirstName = (a: Soignant, b: Soignant) => {
    if (a.prenom && b.prenom) {
      return a.prenom.localeCompare(b.prenom)
    }
    return 0
  }

  return (
    <div data-testid="filtre-soignants" className="flex">
      <Listbox value={selection} onChange={setSelection} multiple>
        <ListboxButton className="group">
          <ToolButton title={t("messagerie.ajouterParticipant")} icon={Collaboration} type="outlined" />
        </ListboxButton>
        <div className="relative z-10">
          <ListboxOptions className="w-54 absolute right-0 top-5 max-h-64 gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow">
            {titre && <div className="mb-1 text-h7">{titre.toUpperCase()}</div>}
            {liste.sort(byFirstName).map((item) => (
              <ListboxOption
                key={item.id}
                data-testid={"soignant-option-" + item.id}
                value={item}
                className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
              >
                {({ selected }) => (
                  <>
                    <input
                      type="checkbox"
                      checked={selected}
                      className="outline-1 ui-selected:accent-dark-plum"
                      onChange={() => {}} // This is needed to prevent a warning
                    />
                    <div className="w-5">
                      <Participant prenom={item.prenom} nom={item.nom} urlAvatar={item.urlAvatar} id={item.id} />
                    </div>
                    <div className="truncate ui-selected:text-dark-plum">{buildNom(item)}</div>
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  )
}
