import { Soignant } from "@features/messagerie/hooks/useListeSoignantsDuCabinet.ts"
import { useParticipants } from "@features/messagerie/hooks/useParticipants.ts"
import { MessageInput, MessageList, Window } from "stream-chat-react"
import { usePatientDuChannel } from "@features/messagerie/hooks/usePatientDuChannel.ts"
import { HeaderWithTools } from "@features/messagerie/HeaderWithTools.tsx"
import { CustomMessage } from "@features/messagerie/CustomMessage.tsx"
import { Warning } from "@ds/icons/Warning.tsx"
import { CustomMessageInput } from "@features/messagerie/CustomMessageInput.tsx"
import { useTranslation } from "react-i18next"

interface ChannelInnerProps {
  listeSoignantsDuCabinet?: Soignant[]
  setAfficherConfirmation: (value: boolean) => void
}

export const ChannelInner = ({ listeSoignantsDuCabinet, setAfficherConfirmation }: ChannelInnerProps) => {
  const { updateParticipants } = useParticipants()
  const { patient, nonOnboarde, profilPrincipal } = usePatientDuChannel()

  return (
    <Window>
      <HeaderWithTools
        patient={patient}
        nonOnboarde={nonOnboarde}
        profilPrincipal={profilPrincipal}
        listeSoignantsDuCabinet={listeSoignantsDuCabinet}
        onClickArchiver={() => setAfficherConfirmation(true)}
        updateParticipants={updateParticipants}
      />
      <MessageList Message={CustomMessage} showUnreadNotificationAlways={false} />
      {nonOnboarde && <NonOnboarde />}
      <MessageInput
        Input={() => <CustomMessageInput onClickReactiver={() => setAfficherConfirmation(true)} />}
        publishTypingEvent={true}
      />
    </Window>
  )
}

const NonOnboarde = () => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full justify-start">
      <div className="flex items-center gap-1">
        <Warning className="text-grey" />
        <div className="text-p-tiny text-grey" data-testid="no-app-message">
          {t("messagerie.patientNonOnboarde")}
        </div>
      </div>
    </div>
  )
}
