import { di } from "@di"
import { createRootRoute, Outlet } from "@tanstack/react-router"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

type RootRouteSearchParams = {
  modal?: boolean
}

export const rootRoute = createRootRoute({
  component: () => {
    const { RecherchePatientModal } = di
    return (
      <>
        <Outlet />
        <RecherchePatientModal />
        <ToastContainer />
      </>
    )
  },
  validateSearch: (search: Record<string, unknown>): RootRouteSearchParams => {
    delete search["code"] // remove Auth0 code after redirect
    delete search["state"] // remove Auth0 state after redirect
    return { modal: typeof search.modal == "boolean" ? search.modal : undefined }
  },
})
