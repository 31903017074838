import { SVGProps } from "react"

export const Undo = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.3125C12.4173 3.3125 15.1875 6.08274 15.1875 9.5C15.1875 12.9173 12.4173 15.6875 9 15.6875C5.58274 15.6875 2.8125 12.9173 2.8125 9.5C2.8125 9.18934 2.56066 8.9375 2.25 8.9375C1.93934 8.9375 1.6875 9.18934 1.6875 9.5C1.6875 13.5386 4.96142 16.8125 9 16.8125C13.0386 16.8125 16.3125 13.5386 16.3125 9.5C16.3125 5.46142 13.0386 2.1875 9 2.1875C6.92173 2.1875 5.04638 3.05466 3.71553 4.44568V2.75C3.71553 2.43934 3.46369 2.1875 3.15303 2.1875C2.84237 2.1875 2.59053 2.43934 2.59053 2.75V6.125C2.59053 6.43566 2.84237 6.6875 3.15303 6.6875L6.46875 6.6875C6.77941 6.6875 7.03125 6.43566 7.03125 6.125C7.03125 5.81434 6.77941 5.5625 6.46875 5.5625H4.22672C5.36215 4.18761 7.07911 3.3125 9 3.3125Z"
      fill="#312E81"
    />
  </svg>
)
