import { graphql } from "@data/gql"

export const patientParIdentifiantQuery = graphql(`
  query patientParIdentifiantQuery($identifiant: String!) {
    patientParIdentifiant(identifiant: $identifiant) {
      id
      prenom
      nom
      dateDeNaissance
      sexe
      numeroDeTelephone
      compte {
        profilPrincipal {
          id
        }
      }
      lienDoctolib
      nouveauPatient
    }
  }
`)
