import { SVGProps } from "react"

export const Vaccine = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#101010"
      fillRule="evenodd"
      d="M2.84 6.042a.482.482 0 0 1 0-.682l2.728-2.727a.482.482 0 1 1 .682.681l-.34.341L7.613 5.36l1.363-1.363a.482.482 0 1 1 .682.682l-.682.682 5.796 5.795c.699.7.879 1.72.54 2.586l.482.483c.253.252.336.61.25.93l1.114 1.115a.482.482 0 0 1-.682.682l-1.114-1.114a.964.964 0 0 1-.931-.25l-.483-.482a2.411 2.411 0 0 1-2.585-.54l-.892-.892 2.82-2.82-4.904-4.904-2.23 2.23.986.988H5.966l-.398-.398-.682.681a.482.482 0 1 1-.681-.681l1.363-1.364-1.704-1.704-.341.34a.482.482 0 0 1-.682 0Zm2.48-1.798-.867.867 1.704 1.705.867-.867L5.32 4.244Zm6.633 9.731a1.578 1.578 0 0 0 1.692.353l.892-.891a1.577 1.577 0 0 0-.354-1.692l-.21-.21-2.23 2.23.21.21Zm2.82.59a2.51 2.51 0 0 1-.095.09l.343.343a.13.13 0 0 0 .185-.185l-.343-.343a2.506 2.506 0 0 1-.09.095Z"
      clipRule="evenodd"
    />
    <path
      fill="#101010"
      d="M6.667 11.667c.23 0 .416.186.416.416v.834h.834a.417.417 0 1 1 0 .833h-.834v.833a.417.417 0 0 1-.833 0v-.833h-.833a.417.417 0 0 1 0-.833h.833v-.834c0-.23.187-.416.417-.416Z"
    />
    <path
      fill="#101010"
      fillRule="evenodd"
      d="M3.608 15.704a5.051 5.051 0 0 0 2.85 1.796 5.05 5.05 0 0 0 2.85-1.796 5.146 5.146 0 0 0 1.109-3.205V11c0-.264-.105-.519-.29-.706a.984.984 0 0 0-.7-.293H3.49a.984.984 0 0 0-.7.293c-.185.187-.289.442-.289.707v1.5a5.146 5.146 0 0 0 1.108 3.204Zm5.975-3.205v.002a4.313 4.313 0 0 1-.928 2.686 4.221 4.221 0 0 1-2.197 1.455 4.222 4.222 0 0 1-2.196-1.455 4.313 4.313 0 0 1-.929-2.686V11c0-.047.019-.09.05-.121a.15.15 0 0 1 .106-.046h5.938a.15.15 0 0 1 .107.046c.03.03.05.074.05.12v1.5Z"
      clipRule="evenodd"
    />
  </svg>
)
