import { Ai } from "@ds/icons/Ai"
import { CheckRound } from "@ds/icons/CheckRound"
import { format } from "date-fns"
import { RendezVousParCabinetAPI } from "@data/rendezVous/queries/rendezVousParCabinetQuery.ts"
import { WavingMan } from "@ds/icons/WavingMan"
import { LieuDuRendezVous, StatutDePreparation } from "@data/gql/graphql"
import { Avatar } from "@ds/avatar/Avatar"
import { classNames } from "@ds/classNames"
import { Subtract } from "@ds/icons/Subtract"
import { fr } from "date-fns/locale"
import { PrimaryApp } from "@ds/icons/PrimaryApp.tsx"
import { t } from "i18next"

type InformationsDeRendezVousProps = {
  rendezVous: RendezVousParCabinetAPI
  displayPatientName?: boolean
  formatDate?: string
  onClick: () => unknown
}

export const InformationsDeRendezVous = ({
  rendezVous,
  formatDate = "HH:mm",
  displayPatientName = true,
  onClick,
}: InformationsDeRendezVousProps) => {
  const { id, dateHeureDeDebut, medecin, patient, motif, patientEnSalleDAttente, preconsultation, lieu } = rendezVous
  const heure = format(dateHeureDeDebut, formatDate, { locale: fr }).toUpperCase()
  const nomPatient = patient?.prenom + " " + patient?.nom

  const preparationTerminee = preconsultation?.preparation.statut == StatutDePreparation.PreparationTerminee
  const prepareeViaLLM = preparationTerminee && preconsultation?.preparation.viaLLM == true
  const patientPossedeLApplication = patient?.compte

  return (
    <>
      <div
        key={id}
        onClick={onClick}
        className={classNames(
          "group flex h-14 cursor-pointer gap-2 rounded-xl py-4 pr-4 shadow-drop-shadow hover:shadow-hover-drop-shadow",
          { "opacity-50": rendezVous.consultationTerminee == true },
        )}
      >
        {patientEnSalleDAttente && <WavingMan id={id} />}
        <div
          className={classNames("flex min-w-0 flex-1 items-center gap-6", {
            "ml-[-8px]": patientEnSalleDAttente == true,
          })}
        >
          <div className="ml-6 flex h-5 items-center rounded bg-extra-light-grey p-1 text-h7">{heure}</div>
          {displayPatientName && (
            <div className="flex min-w-0 flex-shrink gap-1">
              <div className="truncate text-h5">{nomPatient}</div>
              {patientPossedeLApplication && <PrimaryApp />}
            </div>
          )}
        </div>
        <div className="flex flex-[0.8] items-center gap-2">
          {medecin?.photo ? (
            <img className="h-5 w-5 rounded" src={medecin.photo} />
          ) : (
            <Avatar name={medecin?.nom.replace("Dr. ", "")} style="small" />
          )}
          <div className="truncate text-h5">{medecin?.nom}</div>
        </div>
        <div className="flex min-w-0 flex-[1.5] items-center">
          <div className="truncate text-h5">
            {lieu == LieuDuRendezVous.EnTeleconsultation ? t("teleconsultation") : preconsultation.motif?.nom || motif}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Ai className={prepareeViaLLM ? "text-lg text-light-grey" : "invisible"} />
          <div
            className={classNames("flex h-6 w-6 items-center justify-center rounded", {
              "bg-secondary-green": rendezVous.preconsultation.validee,
            })}
          >
            {rendezVous.preconsultation.validee ? (
              <Subtract data-testid="preparation-validee" className="text-h4 text-valid" />
            ) : (
              <CheckRound
                data-testid="check"
                className={classNames("w-[14px]", preparationTerminee ? "text-blue" : "invisible")}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
