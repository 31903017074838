import { SVGProps } from "react"
export const HeartCardiogram = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.31 4.995c-.608.774-.977 1.832-.977 2.936 0 .64.11 1.254.304 1.837h3.62l1.41-2.51.883 3.088 1.08-1.388h2.703v.834h-2.296L9.2 12.154l-.785-2.746-.671 1.194H1.667v-.834h1.098A6.575 6.575 0 0 1 2.5 7.931c0-1.281.425-2.523 1.155-3.45.731-.932 1.785-1.564 3.025-1.564 1.46 0 2.57.818 3.32 1.972.75-1.154 1.86-1.972 3.32-1.972 1.24 0 2.293.632 3.025 1.563.73.929 1.155 2.17 1.155 3.451 0 2.743-1.893 5.033-3.684 6.596a20.498 20.498 0 0 1-3.54 2.465l-.063.033-.016.009-.005.002-.001.001-.191-.37-.181.375-.003-.001-.005-.003-.021-.01a8.153 8.153 0 0 1-.365-.19c-.245-.134-.59-.33-.998-.585a18.21 18.21 0 0 1-2.882-2.222 12.49 12.49 0 0 1-1.509-1.72.417.417 0 0 1 .677-.486c.419.583.903 1.12 1.408 1.604.95.91 1.967 1.627 2.75 2.118a17.68 17.68 0 0 0 1.123.65l.106-.06a19.658 19.658 0 0 0 3.168-2.238c1.75-1.527 3.398-3.605 3.398-5.968 0-1.104-.368-2.162-.976-2.936-.607-.771-1.435-1.245-2.37-1.245-1.293 0-2.295.842-2.946 2.167L10 6.68l-.374-.762C8.976 4.592 7.972 3.75 6.68 3.75c-.936 0-1.764.473-2.37 1.245ZM10 16.667l-.181.375.187.09.184-.095-.19-.37Z"
      clipRule="evenodd"
    />
  </svg>
)
