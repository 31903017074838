import { PageTitleLayout } from "@ds/layout/PageLayout"
import { useTranslation } from "react-i18next"
import { ConversationCard } from "./ConversationCard"
import {
  Channel,
  ChannelList,
  ChannelListMessengerProps,
  LoadMorePaginator,
  useChannelListContext,
  useChatContext,
} from "stream-chat-react"
import { useConnectedToChat } from "@infra/messagerie/useConnectedToChat"
import { CustomTypingIndicator } from "@features/messagerie/CustomTypingIndicator.tsx"
import { CustomSystemMessage } from "@features/messagerie/CustomSystemMessage.tsx"
import { useArchivage } from "@features/messagerie/hooks/useArchivage.tsx"
import { ChannelListEmptyStateIndicator } from "./ChannelListEmptyStateIndicator"
import { NoChannel } from "@features/messagerie/NoChannel.tsx"
import { useListeSoignantsDuCabinet } from "@features/messagerie/hooks/useListeSoignantsDuCabinet.ts"
import { useNouveauMessage } from "@features/messagerie/hooks/useNouveauMessage.tsx"
import { useFlags } from "launchdarkly-react-client-sdk"
import { ChannelInner } from "@features/messagerie/ChannelInner.tsx"
import { usePreferences } from "@infra/preferences/usePreferences"
import { CustomList } from "./CustomList"
import { PlainButton } from "@ds/button/PlainButton"
import { filtreConversations } from "./filtreConversations"
import { CustomDateSeparator } from "./CustomDateSeparator"
import "./messagerie.css"
import { channelRenderFilterFn } from "@features/messagerie/channelRenderFilterFn.ts"
import { ChannelSort } from "stream-chat"
import { DropDownFiltreMessagerie, FiltreMessagerie } from "@features/messagerie/FiltreMessagerie.tsx"
import { useEffect, useState } from "react"

export const Messagerie = () => {
  const { t } = useTranslation()
  const { client, channel } = useChatContext()

  const options = { limit: 30, watch: true }
  const isUserMedecin = client.user?.metier === "medecin"
  const sort = isUserMedecin ? ({ demandeAideMedecin: 1, last_message_at: -1 } as unknown as ChannelSort) : undefined

  const { Modale, setAfficherConfirmation } = useArchivage()

  const { listeSoignantsDuCabinet } = useListeSoignantsDuCabinet()

  const { BoutonNouveauMessage, ModaleNouveauMessage } = useNouveauMessage()

  const { messagerieOutbound } = useFlags()

  const { identifiantDuCabinet, filtreMessagerie, setPreferences } = usePreferences()

  const filtres = [
    {
      id: "ma-boite",
      nom: t("messagerie.maBoite"),
    },
    {
      id: "tous",
      nom: t("messagerie.tous"),
    },
    {
      id: "archive",
      nom: t("messagerie.archives"),
    },
  ].filter((filtre) => !(isUserMedecin && filtre.id === "tous"))

  const filtreMessagerieSelectionne = filtres.find((filtre) => filtre.id === filtreMessagerie) || filtres[0]

  const filtreDesConversations = filtreConversations(identifiantDuCabinet!, client.user!.id, isUserMedecin)

  const [filteredChannelsCount, setFilteredChannelsCount] = useState(0)

  const isConnected = useConnectedToChat()
  if (!isConnected) return

  const CustomListCountingChannels = (props: ChannelListMessengerProps) => {
    const { channels } = useChannelListContext()

    useEffect(() => {
      const filteredChannels = filtreMessagerie ? channelRenderFilterFn(channels, filtreMessagerie, client) : []
      setFilteredChannelsCount(filteredChannels.length)
    }, [channels])

    return <CustomList {...props} filters={filtreDesConversations} />
  }

  return (
    <PageTitleLayout
      title={t("messagerie.messagerie")}
      margin="ml-8 mt-8"
      className="flex h-screen flex-col"
      actions={
        <>
          {messagerieOutbound && <BoutonNouveauMessage />}
          {messagerieOutbound && <ModaleNouveauMessage />}
        </>
      }
    >
      {filtreMessagerie && (
        <div className="mx-8 grid flex-1 grid-cols-[16rem_1fr] gap-4 overflow-hidden">
          <div className="flex flex-col gap-3 overflow-hidden border-r-[1px] border-extra-light-grey pr-2">
            <FiltreMessagerie
              filtres={filtres}
              selection={filtreMessagerieSelectionne}
              setSelection={(filtreSelectionne: DropDownFiltreMessagerie) =>
                setPreferences({ filtreMessagerie: filtreSelectionne.id })
              }
              count={filtreMessagerieSelectionne.id === "archive" ? null : filteredChannelsCount}
            />
            <ChannelList
              List={(props) => <CustomListCountingChannels {...props} />}
              Preview={(props) => (
                <ConversationCard {...props} displaysNew={filtreMessagerieSelectionne.id !== "archive"} />
              )}
              options={options}
              channelRenderFilterFn={(channelList) => channelRenderFilterFn(channelList, filtreMessagerie, client)}
              filters={filtreDesConversations}
              sort={sort}
              allowNewMessagesFromUnfilteredChannels={false}
              EmptyStateIndicator={ChannelListEmptyStateIndicator}
              setActiveChannelOnMount={false}
              Paginator={(props) => (
                <LoadMorePaginator
                  {...props}
                  LoadMoreButton={() =>
                    filtreMessagerie !== "archive" && props.hasNextPage ? null : (
                      <PlainButton
                        title={t("messagerie.chargerPlus")}
                        onClick={() => {
                          if (props.hasNextPage) {
                            props.loadNextPage()
                          }
                        }}
                        type="outlined"
                        className="mx-auto my-4"
                      />
                    )
                  }
                />
              )}
            />
          </div>

          {channel && (
            <Channel
              UnreadMessagesSeparator={() => {
                return <div className="w-full border-[1px] border-t-extra-light-grey" />
              }}
              TypingIndicator={CustomTypingIndicator}
              UnreadMessagesNotification={() => <div />}
              MessageSystem={CustomSystemMessage}
              EmptyStateIndicator={() => <></>}
              DateSeparator={CustomDateSeparator}
            >
              <ChannelInner
                listeSoignantsDuCabinet={listeSoignantsDuCabinet}
                setAfficherConfirmation={setAfficherConfirmation}
              />
              <Modale />
            </Channel>
          )}
          {!channel && <NoChannel />}
        </div>
      )}
    </PageTitleLayout>
  )
}
