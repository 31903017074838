import { graphql } from "@data/gql"

export const rendezVousQuery = graphql(`
  query rendezVousQuery($identifiantProfil: String!) {
    rendezVous(identifiantProfil: $identifiantProfil) {
      typeDeRendezVous
      id
      dateHeureDeDebut
      dateHeureDeFin
      motif
      patient {
        id
        prenom
        nom
        sexe
        dateDeNaissance
        numeroDeTelephone
        compte {
          identifiantUtilisateur
          numeroDeTelephone
          email
        }
        lienDoctolib
      }
      medecin {
        nom
        photo
      }
      consultation {
        complete
      }
      preconsultation {
        motif {
          nom
        }
        preparation {
          statut
          viaLLM
        }
        validee
      }
      patientEnSalleDAttente
      lieu
      consultationTerminee
    }
  }
`)
