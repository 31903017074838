import { SVGProps } from "react"

export const CheckRound = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <div className="pb-1 pt-1">
    <svg width="1em" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8138 1.10925C13.0447 1.31707 13.0634 1.67273 12.8556 1.90364L7.05243 8.35164C6.32152 9.16375 5.08411 9.26498 4.23095 8.58244L1.58613 6.46659C1.34354 6.27252 1.30421 5.91854 1.49828 5.67596C1.69235 5.43337 2.04633 5.39404 2.28891 5.58811L4.93373 7.70396C5.32153 8.01421 5.88399 7.9682 6.21622 7.59905L12.0194 1.15106C12.2272 0.920144 12.5829 0.901425 12.8138 1.10925Z"
        fill="currentColor"
      />
      <path
        d="M12.8138 1.10925L13.1483 0.737599V0.737599L12.8138 1.10925ZM12.8556 1.90364L12.484 1.56916V1.56916L12.8556 1.90364ZM7.05243 8.35164L6.68078 8.01716H6.68078L7.05243 8.35164ZM4.23095 8.58244L4.5433 8.19201H4.54329L4.23095 8.58244ZM1.58613 6.46659L1.89848 6.07615L1.58613 6.46659ZM1.49828 5.67596L1.88872 5.98831L1.88872 5.98831L1.49828 5.67596ZM2.28891 5.58811L1.97656 5.97854L1.97656 5.97854L2.28891 5.58811ZM4.93373 7.70396L5.24608 7.31353H5.24608L4.93373 7.70396ZM6.21622 7.59905L6.58787 7.93354V7.93354L6.21622 7.59905ZM12.0194 1.15106L12.3911 1.48554L12.3911 1.48554L12.0194 1.15106ZM12.4793 1.48089C12.505 1.50398 12.5071 1.5435 12.484 1.56916L13.2273 2.23812C13.6198 1.80196 13.5845 1.13015 13.1483 0.737599L12.4793 1.48089ZM12.484 1.56916L6.68078 8.01716L7.42407 8.68612L13.2273 2.23812L12.484 1.56916ZM6.68078 8.01716C6.12706 8.6324 5.18963 8.70908 4.5433 8.19201L3.9186 8.97288C4.9786 9.82087 6.51598 9.69511 7.42407 8.68612L6.68078 8.01716ZM4.54329 8.19201L1.89848 6.07615L1.27378 6.85702L3.9186 8.97288L4.54329 8.19201ZM1.89848 6.07615C1.87152 6.05459 1.86715 6.01526 1.88872 5.98831L1.10785 5.36361C0.741275 5.82183 0.815566 6.49045 1.27378 6.85702L1.89848 6.07615ZM1.88872 5.98831C1.91028 5.96135 1.94961 5.95698 1.97656 5.97854L2.60126 5.19768C2.14304 4.8311 1.47442 4.90539 1.10785 5.36361L1.88872 5.98831ZM1.97656 5.97854L4.62138 8.0944L5.24608 7.31353L2.60126 5.19768L1.97656 5.97854ZM4.62138 8.0944C5.21601 8.5701 6.07845 8.49956 6.58787 7.93354L5.84457 7.26457C5.68953 7.43684 5.42705 7.45831 5.24608 7.31353L4.62138 8.0944ZM6.58787 7.93354L12.3911 1.48554L11.6478 0.816574L5.84457 7.26457L6.58787 7.93354ZM12.3911 1.48554C12.4142 1.45988 12.4537 1.4578 12.4793 1.48089L13.1483 0.737599C12.7121 0.345048 12.0403 0.380406 11.6478 0.816574L12.3911 1.48554Z"
        fill="currentColor"
      />
    </svg>
  </div>
)
