import { useFlags } from "launchdarkly-react-client-sdk"
import { useChatContext } from "stream-chat-react"
import { useMessagerieUnreadCount } from "./hooks/useMessagerieUnreadCount"
import { usePreferences } from "@infra/preferences/usePreferences"
import { Flag } from "@ds/icons/Flag"

export const UnreadCount = () => {
  const { sideBarOuverte } = usePreferences()
  const { messagerieStream, messagerieMedecins } = useFlags()
  const { unreadCount, unreadCountAideMedecin } = useMessagerieUnreadCount()
  const { client } = useChatContext()

  if (messagerieMedecins && client.user?.metier === "medecin") {
    if (unreadCountAideMedecin > 0) {
      return (
        <>
          {sideBarOuverte ? (
            <div className="flex min-w-4 items-center gap-[2px] rounded-full bg-yellow p-1 text-p-x-small text-white">
              <div>{unreadCountAideMedecin}</div>
              <Flag className="text-p-tiny" />
            </div>
          ) : (
            <div className="absolute bottom-2 right-[-10px]">
              <div className="flex min-w-4 items-center gap-[2px] rounded-full bg-yellow p-1 text-p-x-small text-white">
                <div>{unreadCountAideMedecin}</div>
                <Flag className="text-p-tiny" />
              </div>
            </div>
          )}
        </>
      )
    }
  }
  if (messagerieStream && (unreadCount ?? 0) > 0 && client.user?.metier !== "medecin") {
    return sideBarOuverte ? (
      <div className="min-w-4 rounded-full bg-yellow p-[2px] text-p-x-small text-white">{unreadCount}</div>
    ) : (
      <div className="absolute bottom-2 right-0">
        <div className="min-w-4 rounded-full bg-yellow p-[2px] text-p-x-small text-white">{unreadCount}</div>
      </div>
    )
  }
  return <></>
}
