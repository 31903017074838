import { BehaviorSubject, Observable } from "rxjs"
import { UpdateService } from "./UpdateService"

export class CallbackUpdateService implements UpdateService {
  constructor(callback: () => Promise<unknown>) {
    this._callback = callback
  }

  _callback: () => Promise<unknown>

  _canUpdate = new BehaviorSubject(false)

  set canUpdate(value: boolean) {
    this._canUpdate.next(value)
  }

  get canUpdate(): Observable<boolean> {
    return this._canUpdate
  }

  update = () => (this._canUpdate.value ? this._callback() : Promise.resolve())
}
