import { SVGProps } from "react"

export const NoPrimaryApp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="18" height="18" rx="4" fill="#F8D4EA" />
    <path
      d="M8.68225 4.60278H9.75514M12.4374 5.58956V5.20556C12.4374 4.88582 12.3244 4.57918 12.1231 4.3531C11.9219 4.12701 11.649 4 11.3645 4H7.0729C6.78835 4 6.51545 4.12701 6.31424 4.3531C6.11304 4.57918 6 4.88582 6 5.20556V13.6444C6 13.9642 6.11304 14.2708 6.31424 14.4969C6.51545 14.723 6.78835 14.85 7.0729 14.85H11.3645C11.649 14.85 11.9219 14.723 12.1231 14.4969C12.3244 14.2708 12.4374 13.9642 12.4374 13.6444V13.0477"
      stroke="#101010"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 12.0361L14.9023 6.82437" stroke="#101010" />
  </svg>
)
