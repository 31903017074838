import { useEffect, useMemo } from "react"
import { ChannelFilters, ChannelSort, DefaultGenerics } from "stream-chat"
import {
  ChannelListMessenger,
  ChannelListMessengerProps,
  MAX_QUERY_CHANNELS_LIMIT,
  useChannelListContext,
  useChatContext,
} from "stream-chat-react"
import uniqBy from "lodash.uniqby"

export const CustomList = (
  props: ChannelListMessengerProps<DefaultGenerics> & {
    filters: ChannelFilters<DefaultGenerics>
  },
) => {
  const { channels, setChannels } = useChannelListContext()
  const { client } = useChatContext()
  const filters = props.filters

  // memoize props
  const filterString = useMemo(() => JSON.stringify(filters), [filters])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryChannels = async () => {
    try {
      const options = {
        limit: MAX_QUERY_CHANNELS_LIMIT,
        message_limit: 300,
        watch: true,
      }
      const sort =
        client.user?.metier === "medecin"
          ? ({ demandeAideMedecin: 1, last_message_at: -1 } as unknown as ChannelSort)
          : undefined

      const channelQueryResponse = await client.queryChannels(filters, sort, options)

      const newChannels = uniqBy([...channelQueryResponse, ...channels], "cid")

      setChannels(newChannels)
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryChannels()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [filterString, queryChannels])

  return <ChannelListMessenger {...props} />
}
