import { rendezVousQuery } from "@data/rendezVous/queries/rendezVous"
import { useGraphQLQuery } from "@data/useGraphQL"
import { PageLayout } from "@ds/layout/PageLayout"
import { EntetePatient } from "@features/rendezVous/detailPreconsultation/components/EntetePatient"
import { ListeDeRendezVousParPatient } from "@features/rendezVous/listeDeRendezVous/ListeDeRendezVousParPatient"
import { appRoute, patientRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"

export const Patient = () => {
  const { id } = patientRoute.useParams()
  const navigate = useNavigate()

  const { data } = useGraphQLQuery({ document: rendezVousQuery, variables: { identifiantProfil: id } })

  return (
    <PageLayout className="flex flex-col">
      <div className="mx-10 mt-4 flex flex-1 flex-col">
        {data?.data?.rendezVous?.[0]?.patient && (
          <EntetePatient
            patient={data?.data?.rendezVous?.[0]?.patient}
            onBack={() =>
              navigate({
                to: appRoute.to,
                search: { date: format(new Date(), "yyyy-MM-dd") },
              })
            }
          />
        )}
        <div className="h-6" />
        {data?.data?.rendezVous && <ListeDeRendezVousParPatient rendezVous={data?.data?.rendezVous} />}
      </div>
    </PageLayout>
  )
}
