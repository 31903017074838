import waving from "../../assets/waving.png"
type WavingManProps = {
  id: string
}

export const WavingMan = ({ id }: WavingManProps) => (
  <div className="relative">
    <div className="absolute ml-[-14px] h-7 w-7 rounded-full bg-white shadow-hover-drop-shadow">
      <div className="pl-2 pr-2 pt-[7px]">
        <img data-testid={"waving-" + id} key={"waving-" + id} src={waving}></img>
      </div>
    </div>
  </div>
)
