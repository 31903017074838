import { SVGProps } from "react"
export const Loader = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" {...props}>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <circle cx={9} cy={9} r={7.5} fill="#101010" opacity={0.3} />
        <path stroke="#101010" strokeLinecap="round" strokeWidth={1.3} d="M16.5 9A7.5 7.5 0 1 1 3.697 3.697" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)
