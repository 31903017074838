import { graphql } from "@data/gql"
import { AideSuggestionsQueryQuery } from "@data/gql/graphql"

export type SuggestionAPI = AideSuggestionsQueryQuery["aideALaPreanamnese"]["suggestions"][number]

export const aideSuggestions = graphql(`
  query aideSuggestionsQuery($contenu: String!) {
    aideALaPreanamnese(contenu: $contenu) {
      suggestions {
        contenu
      }
    }
  }
`)
