import { ChevronDown } from "@ds/icons/ChevronDown"
import { ChevronUp } from "@ds/icons/ChevronUp"
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react"

export type DropDownFiltreMessagerie = { id: string; nom: string }

type FiltreMessagerieProps = {
  filtres: DropDownFiltreMessagerie[]
  selection: DropDownFiltreMessagerie
  setSelection: (value: DropDownFiltreMessagerie) => void
  count: number | null
}

export const FiltreMessagerie = ({ selection, setSelection, filtres, count }: FiltreMessagerieProps) => {
  return (
    <div data-testid="filtre-messagerie" className="z-10">
      <Listbox value={selection} onChange={setSelection}>
        {({ open }) => (
          <>
            <ListboxButton className="group w-full">
              <div className="flex w-full items-center justify-between gap-2 rounded-md border border-transparent bg-extra-light-grey px-3 py-2 text-h6 group-hover:border-black group-hover:bg-white">
                <div data-testid={"selected-placeholder"} className="truncate text-left text-h6">
                  <div className="flex items-center gap-1">
                    <div>{selection.nom}</div>{" "}
                    <div className="text-p-tiny text-grey">{count === null ? "" : count}</div>
                  </div>
                </div>
                <div className="text-[16px]">{open ? <ChevronUp /> : <ChevronDown />}</div>
              </div>
            </ListboxButton>
            <div className="relative">
              <ListboxOptions className="absolute top-1 max-h-64 w-full gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow">
                {filtres.map((item) => (
                  <ListboxOption
                    key={item.id}
                    data-testid={"filtre-option-" + item.id}
                    value={item}
                    className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
                  >
                    <div className="truncate ui-selected:text-dark-plum">{item.nom}</div>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
